import { FC } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

interface BesoksloggListProps {
  lager?: string;
  tid?: number;
  sms?: boolean;
  tlf?: string;
}

function capitalize(s: string) {
  return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
}

const BesoksloggList: FC<BesoksloggListProps> = ({
  lager = "",
  tid = 0,
  sms = false,
  tlf = "",
}) => {
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col xs={3} md={3}>
            <div className="text-md pt-2">
              {new Date(tid * 1000).toLocaleString(`no-NO`)}
            </div>
          </Col>
          <Col xs={3} md={3}>
            <div className="text-md pt-2">{capitalize(lager)}</div>
          </Col>
          <Col xs={4} md={3}>
            <div className="text-md pt-2 pl-2">{tlf.slice(3,11)}</div>
          </Col>
          <Col xs={3} md={3}>
            <div className="text-md pt-2 pl-2">{sms ? "Ja" : "Nei"}</div>
          </Col>
        </Row>
        <hr
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "100%",
            float: "right",
            marginTop: "1rem",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default BesoksloggList;
