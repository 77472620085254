import { FC } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

interface VippsProps {
  name?: string;
  image?: string;
  desc?: string;
  phonenumber?: number;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  totalSum?: number;
}

const Vipps: FC<VippsProps> = ({
  name = "",
  image = "",
  desc = "",
  phonenumber,
  handleChange,
  onClick,
  totalSum,
}) => {
  return (
    <>
      <div className="pb-3 pt-2 font-normal text-2xl tracking-wider whitespace-pre">
        Vipps
      </div>
      <Form.Group className="mb-3" controlId="formPhoneNumber">
        <Form.Label>Mobilnummer</Form.Label>
        <Form.Control
          type="phonenumber"
          placeholder="Skriv inn mobilnummer"
          value={phonenumber}
          onChange={handleChange}
        />
      </Form.Group>
      <Button
        className="text-white bg-[#24292e] mt-3 pt-3 pb-3 w-80"
        variant="secondary"
        type="submit"
        onClick={onClick}
      >
        BETAL {totalSum},-
      </Button>
    </>
  );
};

export default Vipps;
