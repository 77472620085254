// @ts-nocheck
import {
  collection,
  DocumentData,
  getDocs,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
// @ts-nocheck
import ModalComp from "../components/ModalComp.tsx";
// @ts-ignore
import { db } from "../firebase1.ts";
import { useNavigate } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase1.ts";
import DeliveryRoleCard from "../components/DeliveryRoleCard.tsx";
import { useLocation } from "react-router-dom";

const Maintance = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [numberOfDeliveries, setNumberOfDeliveries] = useState(0);
  const [numberOfActiveOrders, setNumberOfActiveOrders] = useState(0);
  const [numberOfOrdersMadeToday, setNumberOfOrdersMadeToday] = useState(0);

  const [sortedOrders, setSortedOrders] = useState<DocumentData[]>([]);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [accountingDone, setAccountingDone] = useState("");
  const [reasonCancel, setReasonCancel] = useState("");

  const [commentOrder, setCommentOrder] = useState("");
  const [commentOrderID, setCommentOrderID] = useState("");
  const [showComment, setShowComment] = useState(false);
  const handleShowCommentCancel = () => setShowComment(false);

  const [idCanceledOrder, setIdCanceledOrder] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const handleShowCancel = () => setShowCancel(false);

  const [showCancelReason, setShowCancelReason] = useState(false);
  const handleShowCancelReason = () => setShowCancelReason(false);

  const [showFailed, setShowFailed] = useState(false);
  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  const functions = getFunctions(app, 'europe-west1');
  const accounting = httpsCallable(functions, "autoAccounting");
  const cancel = httpsCallable(functions, "cancelOrder");
  
  const location = useLocation();

  useEffect(() => {
    if (!sessionStorage.getItem("reload")) {
      sessionStorage.setItem("reload", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reload");
    }
  }, [location]);

  const handleCancelation = () => {
    let data = {
      client_reference_id: idCanceledOrder,
      reason: reasonCancel,
    };

    cancel(data).then((response) => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      handleShowCancelReason();
      handleShowCancel();
    });
  };
  const navigate = useNavigate();

  const handleChangeReason = (e: any) => {
    setReasonCancel(e.target.value);
  };

  const handleChangeComment = (e: any) => {
    setCommentOrder(e.target.value);
  };

  async function changeCommentDB(kommentar: string, orderId: string) {
    const updateObject = {
      [`supplierComment`]: kommentar,
    };
    const gearUpdate = doc(db, "sales", orderId);

    updateDoc(gearUpdate, updateObject)
      .then((e) => {
        setShowComment(false);
        window.location.reload(false);
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  useEffect(() => {
    if (cancelOrder) {
      handleShowCancel();
    }
  }, [cancelOrder]);

  //HENTE DOKUMENT FRA DATABASE
  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "sales"));
    try {
      const doc = await getDocs(q);

      doc.forEach((doc) => {
        if (
          doc.data().paymentStatus === "paid" &&
          doc.data().deliveryService[0].order === true
        ) {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  function capitalizeString(str) {
    str = str.slice(0, -7); // cut off the last 7 characters
    return str
      .toLowerCase()
      .split(",")
      .map((word) => word.trim())
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    countDelivery();
    countActiveDeliveries();
    countOrdersMadeToday();
    setSortedOrders(
      [...data].sort(
        (a, b) => a.tid.startDate.seconds - b.tid.startDate.seconds
      )
    );
  }, [data]);

  const countOrdersMadeToday = async () => {
    let count = 0;
    let today = new Date();

    data.map((doc) => {
      const orderPlaced = new Date(doc.tid.orderPlaced.seconds * 1000);
      if (
        today.getFullYear() === orderPlaced.getFullYear() &&
        today.getMonth() === orderPlaced.getMonth() &&
        today.getDate() === orderPlaced.getDate()
      ) {
        count++;
      }
    });

    return setNumberOfOrdersMadeToday(count);
  };
  const countDelivery = async () => {
    let count = 0;
    let today = new Date();
    data.map((doc) => {
      if (
        doc.deliveryService[0].order === true &&
        doc.tid.startDate.seconds > today.getTime() / 1000
      ) {
        count++;
      }
    });
    return setNumberOfDeliveries(count);
  };

  const countActiveDeliveries = async () => {
    let count = 0;
    let today = new Date();

    data.map((doc) => {
      const start = new Date(doc.tid.startDate.seconds * 1000);
      const end = new Date(doc.tid.endDate.seconds * 1000);

      if (today >= start && today <= end) {
        count++;
      }
    });

    return setNumberOfActiveOrders(count);
  };
  return (
    <div className="mb-10 font-josefin">
      {/* Modal for å sjekke om du vil kansellere ordre*/}
      <ModalComp
        show={showCancel}
        closeModal={() => setShowCancel(false)}
        title="Er du sikker på at du vil kansellere ordren?"
        hide={handleShowCancel}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleShowCancel}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Å kansellere en ordre kan ikke angres. Er du sikker på at du ønsker
            å kansellere orderen?
            <Row className="mt-2 mb-2">
              <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                <Button
                  variant="primary"
                  className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12 ml-2"
                  onClick={() => setShowCancelReason(true)}
                >
                  Ja
                </Button>

                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                  onClick={handleShowCancel}
                >
                  Nei
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å skrive årsak til kansellering*/}
      <ModalComp
        show={showCancelReason}
        closeModal={() => setShowCancelReason(false)}
        title="Hvorfor skal ordren kanselleres?"
        hide={handleShowCancelReason}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleShowCancelReason}
          >
            Lukk
          </Button>
        }
        body={
          <>
            For å kansellere ordren må du skrive en kort begrunnelse. Dette er:
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.CancelReason"
                onChange={handleChangeReason}
              >
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    handleCancelation();
                    setReasonCancel();
                  }}
                >
                  Kanseller ordre
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComp
        show={showComment}
        closeModal={() => setShowComment(false)}
        title={`Intern kommentar på ordre ${commentOrderID.slice(0, 6)}`}
        hide={handleShowCommentCancel}
        body={
          <>
            Se og endre kommentar på bestilling.
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.Comment"
                onChange={handleChangeComment}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${commentOrder}`}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    changeCommentDB(commentOrder, commentOrderID);
                  }}
                >
                  Legg til kommentar
                </Button>
                <Button
                  variant="secondary"
                  className="text-[#24292e] border-[#24292e] text-left ml-2"
                  onClick={handleShowCommentCancel}
                >
                  Lukk
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComp
        show={showFailed}
        closeModal={() => setShowFailed(false)}
        hide={handleCloseFailed}
        body={
          <>
            <p>
              Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
              senere, eller ta kontakt med teknisk ansvarlig.
            </p>
          </>
        }
      />

      <Container>
        <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0">
          Bestillinger med levering
        </div>
        <Row className="justify-content-md-center">
          {sortedOrders.map((item) => {
            let today = new Date();
            let deliveryServices = item.deliveryService
              .filter((service) => service.order === true)
              .map(
                (service) =>
                  service.id.charAt(0).toUpperCase() + service.id.slice(1)
              )
              .join(", "); // join the services array into a string separated by commas
            return item.paymentStatus === "paid" ? (
              <>
                {item.tid.endDate.seconds >= today.getTime() / 1000 ? (
                  <Col md={{ span: 5 }} className="g-4 ">
                    <DeliveryRoleCard
                      startDate={capitalize(
                        new Date(
                          item.tid.startDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      endDate={capitalize(
                        new Date(
                          item.tid.endDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      confirmedDelivery={item.deliveryConfirmed? item.deliveryConfirmed : false}
                      packageType={item.pakkeType}
                      orderIdLink={item.id}
                      orderId={item.id.slice(0, 6)}
                      phonenumber={item.tlf.slice(3, 11)}
                      storage={capitalizeString(item.deliveryService[3].order)}
                      storageLocation={item.lager}
                      deliveryServicesDelivery={
                        item.deliveryService[0].order === true
                          ? capitalize(item.deliveryService[0].id)
                          : null
                      }
                      deliveryServicesDeliveryTime={
                        item.deliveryService[0].order === true
                          ? item.deliveryService[0].tidspunkt
                          : null
                      }
                      deliveryServicesPickup={
                        item.deliveryService[1].order === true
                          ? capitalize(item.deliveryService[1].id)
                          : null
                      }
                      deliveryServicesPickupTime={
                        item.deliveryService[1].order === true
                          ? item.deliveryService[1].tidspunkt
                          : null
                      }
                      deliveryServicesMontering={
                        item.deliveryService[2].order === true
                          ? item.deliveryService[2].id
                          : null
                      }
                      setCommentOrderID={setCommentOrderID}
                      setShowComment={setShowComment}
                      setCommentOrder={setCommentOrder}
                      supplierComment={item.supplierComment}
                    />
                  </Col>
                ) : null}
              </>
            ) : null;
          })}
        </Row>
      </Container>
      <Container>
        <div className="font-medium text-xl tracking-wider pb-2 pt-12 lg:pl-28 pl-0">
          Tidligere bestillinger
        </div>
        <Row className="justify-content-md-center opacity-60">
          {sortedOrders.map((item) => {
            let today = new Date();
            let deliveryServices = item.deliveryService
              .filter((service) => service.order === true)
              .map(
                (service) =>
                  service.id.charAt(0).toUpperCase() + service.id.slice(1)
              )
              .join(", "); // join the services array into a string separated by commas

            return item.paymentStatus === "paid" ? (
              <>
                {item.tid.endDate.seconds < today.getTime() / 1000 ? (
                  <Col md={{ span: 5 }} className="g-4 ">
                    <DeliveryRoleCard
                      startDate={capitalize(
                        new Date(
                          item.tid.startDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      endDate={capitalize(
                        new Date(
                          item.tid.endDate.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      packageType={item.pakkeType}
                      confirmedDelivery={true}
                      orderId={item.id.slice(0, 6)}
                      phonenumber={item.tlf.slice(3, 11)}
                      storage={capitalizeString(item.deliveryService[3].order)}
                      deliveryServices={deliveryServices}
                    />
                  </Col>
                ) : null}
              </>
            ) : null;
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Maintance;
