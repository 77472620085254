import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  FaBeer,
  FaCoffee,
  FaMicrophone,
  FaMusic,
  FaLightbulb,
  FaVolumeUp,
  FaVolumeDown,
  FaHeadphones,
  FaPlug,
  FaBatteryFull,
  FaBolt,
  FaCameraRetro,
  FaCocktail,
  FaBroom,
} from "react-icons/fa";

interface OrderListProps {
  navn?: string;
  bilde?: string;
  beskrivelse?: string;
  count?: number;
}

const OrderList: FC<OrderListProps> = ({
  navn = "",
  bilde = "",
  beskrivelse = "",
  count,
}) => {
  function getIconByName(iconName: any) {
    const icons = {
      FaBeer: <FaBeer size={48} />,
      FaCoffee: <FaCoffee size={48} />,
      FaMicrophone: <FaMicrophone size={48} />,
      FaMusic: <FaMusic size={48} />,
      FaLightbulb: <FaLightbulb size={48} />,
      FaVolumeUp: <FaVolumeUp size={48} />,
      FaVolumeDown: <FaVolumeDown size={48} />,
      FaHeadphones: <FaHeadphones size={48} />,
      FaPlug: <FaPlug size={48} />,
      FaBatteryFull: <FaBatteryFull size={48} />,
      FaBolt: <FaBolt size={48} />,
      FaCameraRetro: <FaCameraRetro size={48} />,
      FaCocktail: <FaCocktail size={48} />,
      FaBroom: <FaBroom size={48} />,
    };
    //@ts-ignore
    return icons[iconName] || null;
  }
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col xs={3} md={2}>
            {getIconByName(bilde) || (
              <img
                src={bilde}
                alt=""
                style={{
                  width: "10vh",
                  height: "10vh",
                  objectFit: "contain",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
          </Col>
          <Col xs={6} md={8}>
            <div className="font-bold text-md pt-2">{navn}</div>
            <div>{beskrivelse}</div>
          </Col>

          <Col xs={3} md={2} className="float-right  pt-2">
            Antall: {count}
          </Col>
        </Row>
        <hr
          className="float-right object-right mt-3"
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "83%",
            float: "right",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default OrderList;
