// @ts-ignore
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// @ts-ignore
import BesoksloggList from "../components/BesoksloggList.tsx";

// @ts-ignore
import { db } from "../firebase1.ts";

const Besokslogg = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [sortedVisitors, setSortedVisitors] = useState<DocumentData[]>([]);

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "storage-stamp-entries"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setSortedVisitors([...data].sort((a, b) => b.tid.seconds - a.tid.seconds));
  }, [data]);

  return (
    <div className="font-josefin">
      <Row className="justify-content-md-center mt-10 md:w-11/12 pb-10">
        <Col md={{ span: 11, offset: 1 }} className=" px-4">
          <div className="font-normal text-4xl tracking-wider lg:pb-4">
            {" "}
            Besøkslogg{" "}
          </div>
        </Col>
      </Row>
      <div>
      <Container className="py-2 h-30 lg:px-14">
              <Row className="pl-3">
                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold">Tidspunkt</div>
                </Col>
                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold">Lager</div>
                </Col>
                <Col xs={4} md={3}>
                  <div className="text-md pt-2 font-bold">Mobilnummer</div>
                </Col>
                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold">Er SMS sendt?</div>
                </Col>
              </Row>
        {sortedVisitors.map((doc) => (
          <div>
            
              <BesoksloggList
                tlf={doc.tlf}
                lager={doc.lager}
                tid={doc.tid.seconds}
                sms={doc.sms}
              />
            
          </div>
        ))}
        </Container>
      </div>
    </div>
  );
};

export default Besokslogg;
