//@ts-nocheck
import { Form, InputGroup, Button } from "react-bootstrap";
import { useState } from "react";
import { db } from "../firebase1.ts";
import ModalComp from "../components/ModalComp.tsx";
import { DocumentData, doc, getDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const LogIn = () => {
  const [message, setMessage] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const navigate = useNavigate();

  async function logIn(supplierId: string) {
    const user1 = {
      password: "1234",
      role: {
        0: "admin",
      },
    };
    const user2 = {
      password: "abc",
      role: {
        0: "maintance",
        1: "delivery",
      },
    };
    const list: SetStateAction<DocumentData[]> = [];
    const docRef = doc(db, "suppliers", supplierId);

    try {
      const doc = await getDoc(docRef);
      console.log(doc);
      if (doc.exists()) {
        localStorage.setItem("supplierIdPL", supplierId);
        window.location.href = "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631";
      } else if (supplierId === user1.password) {
        navigate(`/${user1.role[0]}/26f3eb3a-6f6c-48fe-bba8-31e9ab581631`);
      } else if (supplierId === user2.password) {
        navigate(`/${user2.role[0]}/26f3eb3a-6f6c-48fe-bba8-31e9ab581631`);
      } else {
        setInvalidCode(true);
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  return (
    <div
      style={{ backgroundColor: "#e57e22" }}
      className="min-h-screen flex font-josefin  justify-center sm:-pt-10 md:pt-16 lg:pt-0 xl:pt-0 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-md w-full space-y-8 bg-white p-10 mt-10 rounded-xl shadow-lg h-100">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Innlogging
          </h2>
        </div>
        <Form className="mt-8 space-y-6">
          <div className="flex flex-col">
            <InputGroup>
              <Form.Control
                className="appearance-none relative block w-full px-3 py-2.5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Skriv inn passord"
                aria-describedby="basic-addon2"
                onChange={(e) => setMessage(e.target.value)}
              />
            </InputGroup>
            <div className="flex justify-center mt-4">
              <Button
                style={{ backgroundColor: "#e57e22" }}
                className="group relative flex justify-center py-2.5 px-4 border border-transparent text-md font-semibold rounded-md text-white w-1/2 mt-2"
                onClick={() => logIn(message)}
              >
                Logg inn
              </Button>
            </div>
          </div>
        </Form>
      </div>

      {/* Modal for invalid code */}
      <ModalComp
        show={invalidCode}
        closeModal={() => setInvalidCode(false)}
        title="Feil eller ugyldig kode"
        hide={() => setInvalidCode(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-gray-800 text-left ml-16 mt-3"
            onClick={() => {
              setMessage(" ");
              setInvalidCode(false);
            }}
          >
            Lukk
          </Button>
        }
        body="Kode som ble skrevet inn er enten feil eller ikke gyldig. Vennligst prøv igjen."
      />
    </div>
  );
};

export default LogIn;
