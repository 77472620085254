// @ts-ignore
import { Row, Container, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const lydpakker = [
  {
    img_src:
      "https://storage.googleapis.com/partylyd2022.appspot.com/images/kraftigNY5.png",
    title: "Kraftig lydpakke",
    link: "3",
    subtitle: "Lei fra 949,-",
    text: "Vår bestselger, helt enkelt. Dekker alle formål med inntil 100 personer. Brukervennlig med mulighet for ekstra utstyr. Spill fra mobil, PC eller mikrofon.",
  },
  {
    img_src:
      "https://firebasestorage.googleapis.com/v0/b/partylyd2022.appspot.com/o/website-images%2FShowpakke%20(1).jpg?alt=media&token=ed1a130b-a3ec-4224-bfc1-b8784ed4e75c",
    title: "Effektpakke",
    link: "2",
    subtitle: "Lei fra 849,-",
    text: "Lys opp festen med vår effekt-pakke! Derbylys som kan synkroniseres, legg til røykmaskin og mulighet for å skifte farge i takt med musikken. La oss gjøre kvelden uforglemmelig!",
  },
  {
    img_src:
      "https://storage.googleapis.com/partylyd2022.appspot.com/images/storpartyNY.png",
    title: "Stor partypakke",
    link: "1",
    subtitle: "Lei fra 1899,-",
    text: "Legg opp til den beste festen med vår ultimate lyd, lys og bass-pakke! Passer for opptil 150 personer og gir god lydgjengivelse og kraftig bass. Enkel i bruk og kompatibel med ekstra utstyr. Bruk med mobil eller PC. Synkroniser lysene med musikken for en fullendt opplevelse!",
  },
];

const Newsfeed = () => {
  const navigate = useNavigate();

  const navigateToPackage = (site: string) => {
    if (site === "Ordinær lydpakke") {
      navigate(`/pakke/ordinarlydpakke`);
    } else {
      let url = site.replace(/\s/g, "").toLowerCase();
      navigate(`/pakke/${url}`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Container>
        <Row>
          <div id="dib-posts"></div>
        </Row>
      </Container>
      <Container>
        <div className=" font-josefin mx-auto bg-white overflow-hidden max-w-6xl mb-10">
          <div className="md:flex md:shrink-0">
            <Row md={3} className="g-2">
              <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }}>
                <div className="lg:pt-8 pt-6 font-medium text-3xl tracking-wide lg:pb-4 pb-2 ">
                  Noen av våre lyd og lyspakker
                </div>
              </Col>
              {lydpakker.map(
                (object: {
                  img_src: string;
                  title: string;
                  link: string;
                  subtitle: string;
                  text: string;
                }) => (
                  <Col md={{ span: 4, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                    <a
                      href="#!"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      <Card
                        className="rounded-xl p-2 m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToPackage(object.title)}
                      >
                        <Card.Img
                          variant="top"
                          src={object.img_src}
                          style={{
                            width: "100%",
                            height: "38vh",
                            objectFit: "contain",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        />
                        <Card.Body>
                          <Card.Title className="text-3xl tracking-wide font-normal">
                            {object.title}
                          </Card.Title>
                          <Card.Subtitle className="font-bold pb-2 text-base">
                            {object.subtitle}
                          </Card.Subtitle>
                          <Card.Text className="line-clamp-4">{object.text}</Card.Text>
                        </Card.Body>
                      </Card>
                    </a>
                  </Col>
                )
              )}
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Newsfeed;
