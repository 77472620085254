import React from "react";
import ReactDOM from "react-dom";
// @ts-ignore
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

// Import styles
import "./index.css";
// @ts-ignore
import { RoleProvider } from "./components/RoleProvider.tsx";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RoleProvider>
        <App />
      </RoleProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
