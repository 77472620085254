//@ts-nocheck
import { FC, useState } from "react";
import React from "react";
import { Card, Col, Form, Row, Container } from "react-bootstrap";

interface AddExtraEquiptmentCardProps {
  name?: string;
  image?: string;
  desc?: string;
  num?: number;
  count?: number;
  max?: number;
  priceFrom?: number;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddExtraEquiptmentCard: FC<AddExtraEquiptmentCardProps> = ({
  name = "",
  image = "",
  desc = "",
  count = 0,
  max,
  priceFrom,
  onClick,
  onChange,
}) => {
  let objects: any[] = [];
  if (max === 1) {
    objects = [1];
  }

  if (max === 2) {
    objects = [1, 2];
  }

  if (max === 3) {
    objects = [1, 2, 3];
  }

  if (max === 4) {
    objects = [1, 2, 3, 4];
  }

  if (max === 5) {
    objects = [1, 2, 3, 4, 5];
  }

  return (
    <div>
      <Container className="py-2 pb-0 h-30">
        <Row>
          <Col xs={3} md={2}>
            <img
              src={image}
              alt=""
              style={{
                width: "40vh",
                height: "10vh",
                objectFit: "contain",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </Col>
          <Col xs={7} lg={8}>
            <div className="pt-2">
              <div className="font-semibold">{name}</div>
              <div className="font-light">
                {count === 0 ? (
                  <strong>fra {priceFrom},-</strong>
                ) : (
                  <strong>
                    + {priceFrom / count} x {count}
                  </strong>
                )}
              </div>
              <div className="font-normal	">{desc}</div>
            </div>
          </Col>
          <Col xs={2} lg={2}>
            <Form.Control
              as="select"
              aria-label="Legg til"
              onClick={onClick}
              onChange={onChange}
              className="mt-2 px-2 font-bold "

              style={{ backgroundColor: "#2289e5", color: "white" }}
            >
              <option> + Velg</option>
              {objects.map((object) => (
                <option value={object}>{object}</option>
              ))}
            </Form.Control>
            {/*
               <Button
                variant="secondary"
                className="text-white bg-[#24292e] text-left float-right inline text-sm	mt-2"
                onClick={onClick}
              >
                <BsPlus
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    marginBottom: "2px",
                  }}
                />
                LEGG TIL
              </Button> 
  
              */}
          </Col>
        </Row>
        <hr
          className="float-right object-right mt-3"
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "83%",
            float: "right",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default AddExtraEquiptmentCard;
