import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  BsFillTelephoneFill,
  BsPersonGear,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import {
  GrStatusUnknown,
  GrStatusCritical,
  GrStatusDisabled,
} from "react-icons/gr";
import { AiOutlineArrowRight } from "react-icons/ai";

import "../style/icons.css";

interface SalesSupplierCardProps {
  startDate?: any;
  endDate?: any;
  packageType?: string;
  orderId?: any;
  phonenumber?: number;
  totalPrice?: number;
  orderIdLink?: string;
  status?: string;
  done?: boolean;
  supplier?: string;
  onClickToSupplier?: () => void;
}

const SalesSupplierCard: FC<SalesSupplierCardProps> = ({
  startDate,
  endDate,
  packageType = "",
  orderId,
  orderIdLink,
  phonenumber,
  totalPrice,
  supplier,
  status = "Ikke bekreftet",
  done,
  onClickToSupplier,
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  return (
    <div className="tracking-wide font-light ">
      <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigateToOtherSite(`/order-leverandor/${orderIdLink}`)
            }
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {startDate} - {endDate}
              </div>
              <div>
                <strong className="font-medium">{packageType}</strong> -{" "}
                {orderId}
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phonenumber}
                    </strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <MdAttachMoney size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {totalPrice} ,-
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  {status === "Kansellert" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusCritical
                          className="icon_kansellert"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Ikke bekreftet" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusUnknown
                          className="icon_ikkebekreftet"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Bekreftet" && !done ? (
                    <>
                      <div className="float-right">
                        <GrStatusDisabled
                          className="icon_bekreftet"
                          color="[#95bb72]"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {done ? (
                    <>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          Fullført
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="py-6 px-3">
                <div className="flex">
                  <div>
                    <BsPersonGear size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">{supplier}</strong>
                  </div>
                  <Button
                    onClick={onClickToSupplier}
                    className="bg-pl_orange hover:bg-pl_yellow border-none ml-4 -mt-1 rounded-full"
                  >
                    <AiOutlineArrowRight size={20} className="my-1" />
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default SalesSupplierCard;
