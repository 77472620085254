// @ts-nocheck
import {
  Col,
  Row,
  Breadcrumb,
  Container,
  Form,
  FormLabel,
  FormSelect,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import {
  collection,
  DocumentData,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase1.ts";
import React, { useState, useEffect, useRef } from 'react';
import SupplierCategoryCard from "../components/SupplierCategoryCard.tsx";
import { MdOutlineWhereToVote } from "react-icons/md";
import MapSupplier from "../components/MapSupplier.tsx";



function haversineDistance(coord1, coord2) {
  function toRad(value) {
      return (value * Math.PI) / 180;
  }

  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(coord2.lat - coord1.lat);
  const dLng = toRad(coord2.lng - coord1.lng);

  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coord1.lat)) *
          Math.cos(toRad(coord2.lat)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance; // returns distance in kilometers
}

const SupplierCategory = () => {
  let { categoryName } = useParams();
  const category = categoryName || "";

  const navigate = useNavigate();

  // Fetch data from firebase
  const [supplierData, setSupplierData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);

  // Set destination for category
  const [destination, setDestination] = useState("show all");
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const [userLocation, setUserLocation] = useState(null);




  useEffect(() => {
    if (destination.toLowerCase() === "show all") {
      setFilteredPackages(categoryData);
    } else {
      const packagesAtDestination = categoryData.filter(
        (pkg) => pkg.location === destination
      );
      setFilteredPackages(packagesAtDestination);
    }
    
    // compute uniqueLocations after categoryData is defined
    if (categoryData) {
      const uniqueSet = new Set(categoryData.map((pkg) => pkg.location));
      setUniqueLocations([...uniqueSet]);
    }
}, [destination, categoryData]);

  // compute uniqueLocations after categoryData is defined
  /*   let uniqueLocations = [];
  if (categoryData) {
    uniqueLocations = [...new Set(categoryData.map((pkg) => pkg.location))];
  } */

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    const suppliersList: SetStateAction<DocumentData[]> = [];
    const packagesList = [];

    const q = query(collection(db, "suppliers"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        const supplierData = doc.data();
        if (supplierData.packages.length > 0) {
          const filteredPackages = supplierData.packages.filter(
            (pkg) => pkg.kategori === category
          );
          filteredPackages.forEach((pkg) => {
            pkg.location = supplierData.city;
            pkg.lat = supplierData.lat;
            pkg.long = supplierData.long;
            pkg.supplierId = doc.id;
          });
          supplierData.packages = filteredPackages;
          packagesList.push(...filteredPackages);
        }
        suppliersList.push({ id: doc.id, ...supplierData });
      });
      setDestination("show all");
      setSupplierData([...suppliersList]);
      setCategoryData(packagesList);
    } catch (e) {
      console.log(e);
    }
};

const prevCategoryDataRef = useRef();

useEffect(() => {
  prevCategoryDataRef.current = categoryData;
}, [categoryData]);

const prevCategoryData = prevCategoryDataRef.current;

useEffect(() => {
  if (userLocation && categoryData) {
    const sortedByDistance = [...categoryData].sort((a, b) => {

      const distanceA = haversineDistance(userLocation, {
        lat: parseFloat(a.lat),
        lng: parseFloat(a.long),
      });
      const distanceB = haversineDistance(userLocation, {
        lat: parseFloat(b.lat),
        lng: parseFloat(b.long),
      });
      return distanceA - distanceB;
    });

    // Bare oppdater categoryData hvis den sorterte listen faktisk er forskjellig
    if (JSON.stringify(sortedByDistance) !== JSON.stringify(prevCategoryData)) {
      setCategoryData(sortedByDistance);
    }
  }
}, [userLocation, categoryData, prevCategoryData]);

  const relevantSuppliers = supplierData.filter(supplier => 
    supplier.packages && supplier.packages.some(pkg => pkg.kategori === category)
  );


  const handleFindNearestClick = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ lat: latitude, lng: longitude });
        }, (error) => {
            console.error("Error obtaining geolocation", error);
        });
    } else {
        console.log("Geolocation not supported by this browser");
    }
  }

  //DETTE MÅ LASTES OPP I FIREBASE OG HENTES DYNAMISK
  const categories = [
    {
      img_src:
        "https://www.bruksanvisningpdf.no/thumbs/products/l/1546738-soundboks-3.jpg",
      title: "Soundboks",
      id: "soundboks",
      bg: "#E3826F",
      description1: "God lyd og bass for en mindre og praktisk fest. Enkel booking over hele landet.",
      description2: "Oppdag Soundboks 3 – et konsept som definerer festlyd. Bærbar, kraftig, og designet for lettvint gjennomføring. Med enkel booking over hele Norge, kan du nå skape den perfekte stemningen for ditt arrangement. Ikke la musikken være en ettertanke; la den være høydepunktet.",
      faq: [
        {
          question: "Hvordan kobler jeg enheten til Bluetooth?",
          answer: "Slå på Soundboks, åpne Bluetooth-innstillingene på enheten din og velg \"Soundboks\" fra listen. Følg eventuelle instruksjoner som vises på skjermen."
        },
        {
          question: "Hvor lenge varer batteriet på Soundboks?",
          answer: "Batteriet på Soundboks kan vare opptil 40 timer ved moderat volum. Det anbefales å lade den fullt opp før bruk."
        },
        {
          question: "Kan jeg bruke Soundboks utendørs?",
          answer: "Ja, Soundboks er designet for å tåle både innendørs og utendørs bruk. Den er robust og tåler lett regn, men bør ikke utsettes for kraftig regn eller nedsenking i vann."
        },
        {
          question: "Hvor høyt kan Soundboks spille?",
          answer: "Soundboks kan produsere et lydnivå på opptil 126 dB, noe som er sammenlignbart med en live konsert."
        },
        {
          question: "Hvordan vedlikeholder jeg Soundboks?",
          answer: "Hold enheten ren ved å tørke den med en fuktig klut. Unngå å bruke kjemikalier eller slipemidler."
        }
      ],
    },
    {
      img_src:
        "https://www.sirkusagio.no/uploads/MdC2suTL/242x0_242x0/Slushmaskin_leie_trekammer.jpg",
      title: "Slushmaskin",
      id: "slushmaskin",
      description1: "Forfriskende slush for alle anledninger. Perfekt for sommerfester og events.",
      description2: "Ta festen eller arrangementet ditt til neste nivå med vår premium slushmaskin utleie i Norge. Perfekt for sommerfester, barnebursdager eller bedriftseventer, gir vår slushmaskin deilige, kjølige forfriskninger som gjestene dine vil elske. Enkel booking, konkurransedyktige priser. Reserver din slushmaskin nå og server smaken av kjølig glede!",
      faq: [
        {
          question: "Hvordan lager jeg slush?",
          answer: "Fyll maskinen med ønsket mengde vann, tilsett slush-sirup i ønsket smak, og la maskinen kjøre til blandingen blir til slush."
        },
        {
          question: "Hvor lang tid tar det å lage slush?",
          answer: "Det tar vanligvis mellom 30 minutter til 1 time, avhengig av mengden og maskinens kapasitet."
        },
        {
          question: "Kan jeg lage alkoholholdig slush?",
          answer: "Ja, du kan tilsette alkohol til blandingen, men husk at alkohol fryser ved en lavere temperatur enn vann."
        },
        {
          question: "Hvordan rengjør jeg slushmaskinen?",
          answer: "Tøm maskinen for gjenværende slush, skyll med varmt vann og tørk godt før lagring."
        },
        {
          question: "Hvor mye slush kan maskinen produsere?",
          answer: "Dette varierer avhengig av maskinens størrelse og modell. Se brukerveiledningen for spesifikke detaljer."
        }
      ],
    },
    {
      img_src:
        "https://www.sirkusagio.no/uploads/snDni9EQ/737x0_360x0/vognogsuper.png",
      title: "Popcornmaskin",
      id: "popcorn",
      description1: "Server ferskt, velsmakende popcorn på minutter. En autentisk kinoopplevelse.",
      description2: "Gjør filmkvelden, bursdagen eller eventet ditt ekstra spesielt med vår popcornmaskin utleie i Norge. Med bare et trykk på en knapp kan du servere nydelig, ferskt popcorn som smelter i munnen. Våre maskiner er brukervennlige, rene og klare til bruk. Uansett anledning, gir popcornmaskinen en ekstra touch av moro og nostalgi. Book din popcornmaskin i dag og nyt den autentiske kinoopplevelsen!",
      faq: [
        {
          question: "Hvilken type maiskorn bør jeg bruke?",
          answer: "Det anbefales å bruke spesielle popcornmaiskorn for best resultat."
        },
        {
          question: "Hvor lang tid tar det å poppe popcorn?",
          answer: "Det tar vanligvis mellom 3-5 minutter, avhengig av mengden og maskinens kapasitet."
        },
        {
          question: "Kan jeg tilsette smak til popcornet?",
          answer: "Ja, du kan tilsette smør, salt, karamell eller andre smakstilsetninger etter at popcornet er poppet."
        },
        {
          question: "Hvordan rengjør jeg popcornmaskinen?",
          answer: "Tøm maskinen for gjenværende popcorn, tørk innsiden med en fuktig klut og tørk godt før lagring."
        },
        {
          question: "Hvor mye popcorn kan maskinen produsere?",
          answer: "Dette varierer avhengig av maskinens størrelse og modell. Se brukerveiledningen for spesifikke detaljer."
        }
      ],
    },
    {
      img_src:
        "https://showup.no/wp-content/uploads/2015/10/344227017_909292860335349_2579018455381374044_n-600x469.jpg",
      title: "Hoppeslott",
      id: "hoppeslott",
      description1: "Timesvis med moro for barn og voksne. Sikker underholdning for alle anledninger.",
      description2:"Ser du etter den ultimate underholdningen for barna? Vår hoppeslott utleie i Norge er svaret! Med fargerike design og sikre materialer, gir våre hoppeslott timesvis med hopp og glede. Perfekt for bursdager, skolearrangementer eller bare en dag i hagen. Med enkel online booking og levering over hele landet, har det aldri vært enklere å gi barna en dag de aldri vil glemme. Lei ditt hoppeslott nå og se smilene lyse opp!",
      faq: [
        {
          question: "Hvordan setter jeg opp hoppeslottet?",
          answer: "Plasser hoppeslottet på et jevnt underlag, fest alle barduner, koble til den elektriske pumpen og slå den på."
        },
        {
          question: "Hvor mange barn kan bruke hoppeslottet samtidig?",
          answer: "Dette varierer avhengig av hoppeslottets størrelse. Se brukerveiledningen for spesifikke detaljer."
        },
        {
          question: "Er hoppeslottet trygt for barn?",
          answer: "Ja, våre hoppeslott er laget av sikre materialer og designet for barns bruk. Overvåkning av en voksen anbefales alltid."
        },
        {
          question: "Hvordan rengjør og vedlikeholder jeg hoppeslottet?",
          answer: "Tøm hoppeslottet for luft, tørk det med en fuktig klut, og oppbevar det på et tørt sted."
        },
        {
          question: "Kan hoppeslottet brukes i regnvær?",
          answer: "Det anbefales ikke å bruke hoppeslottet i regnvær da det kan bli glatt og utgjøre en sikkerhetsrisiko."
        }
      ]
    }
  ];

// Find the correct category based on the categoryName
const currentCategory = categories.find(cat => cat.id === categoryName);

// If the category exists, extract the descriptions; otherwise, set them to null
const description2 = currentCategory ? currentCategory.description2 : null;


// Hvis kategorien eksisterer, hent FAQ-dataene; ellers sett dem til null
const faqData = currentCategory ? currentCategory.faq : null;

  return (
    <div>
      <div style={{ display: "block", width: 700, padding: 30 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="/leverandor" >Alle kategorier</Breadcrumb.Item>
          <Breadcrumb.Item href={`/leverandor/${category}`}>
            {capitalize(category)}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
        <Row md={3} className="g-2">
        <Col md={{ span: 4, offset: 0 }}>
            <div className="font-normal text-5xl tracking-wider pb-4">
              {" "}
              {capitalize("Leie " + category)}
            </div>
            <div className="font-normal tracking-wider pr-8">
            <div className="p-4 mt-2 border rounded-lg shadow-md bg-gray-50">
  {description2 && <p>{description2}</p>}
</div></div>

<div className="py-2 mt-2 flex items-center">
    <Form className="flex-grow">
        <Form.Group
            controlId="formSelectDestination"
            className="w-9/12"
        >
            <FormLabel className="font-medium antialiased tracking-wide mb-3">
                <div className="justify-between flex mt-2">
                    <div>
                        <MdOutlineWhereToVote size={28} />
                    </div>
                    <div className="ml-3 mt-1 font-josefin">
                        Hvor vil du leie?
                    </div>
                </div>
            </FormLabel>
            <FormSelect
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                className="h-10 font-josefin"
            >
                <option value="show all">Hele Norge</option>
                {uniqueLocations.map((location, index) => (
                    <option key={index} value={location}>
                        {location}
                    </option>
                ))}
            </FormSelect>
        </Form.Group>
    </Form>
    <button 
        onClick={handleFindNearestClick} 
        className="mt-12 mr-8 px-4 py-2 bg-orange-300 text-white rounded-md hover:bg-orange-400 focus:outline-none transition duration-200 ease-in-out"
    >
        Finn nærmeste
    </button>
</div>
            <MapSupplier 
  locations={relevantSuppliers.flatMap(supplier => 
    supplier.packages.map(pkg => ({ 
      lat: supplier.lat, 
      lng: supplier.long,
      supplierId: supplier.id, 
      category: pkg.kategori, // include the category from package
      packageId: pkg.id // include the package ID
    }
    ))
  )} 
/>

          </Col>
          <Col md={{ span: 8, offset: 0 }}>
            {filteredPackages.length > 0 && categoryData ? (
              <Row>
               {filteredPackages.map((item) => {
  const distance = userLocation ? haversineDistance(userLocation, {
    lat: parseFloat(item.lat),
    lng: parseFloat(item.long)
  }) : null;

  return (
    <Col xs={6} md={4} className="my-1 px-2 md:px-1"> 
      <SupplierCategoryCard
        name={"Leie " + item.navn}
        image={item.bilder[0]}
        price={item.startPris}
        location={item.location}
        categoryName={item.kategori}
        supplierId={item.supplierId}
        idPackage={item.id}
        distance={distance}
      />
    </Col>
  );
})}
              </Row>
            ) : (
              <div className="italic">
                Norges ledende nettverk av lyd- og festutstyrleverandører. Kvalitet og pålitelighet for enhver anledning! Vi har ingen tilgjengelig {categoryName} for øyeblikket. Du kan prøve å laste inn siden på nytt.
              </div>
            )}
            {faqData && (
      <div className="mt-4 p-6 border rounded-lg shadow-md bg-gray-50 mr-16 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Ofte stilte spørsmål</h2>
        <ul>
          {faqData.map((faqItem, index) => (
            <li key={index} className="mb-4">
              <p className="font-medium">{faqItem.question}</p>
              <p className="text-gray-600">{faqItem.answer}</p>
            </li>
          ))}
        </ul>
      </div>
      )}
          </Col>
          
        </Row>
        
      </Container>
    </div>
  );
};

export default SupplierCategory;
