import React, { useState } from 'react';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase1.ts"; // Sørg for at denne importen fungerer med ditt byggverktøy
import {
    FaBeer,
    FaCoffee,
    FaMicrophone,
    FaMusic,
    FaLightbulb,
    FaVolumeUp,
    FaVolumeDown,
    FaHeadphones,
    FaPlug,
    FaBatteryFull,
    FaBolt,
 FaCameraRetro, FaCocktail, FaBroom
  } from 'react-icons/fa';

  function generateRandomID() {
    return Math.floor(Math.random() * 10000001);  // Generate a random number between 0 and 1000000 inclusive
  }
  

  const IconPicker = ({ onSelect }) => {
    const icons = [
      { name: 'FaBeer', icon: <FaBeer /> },
      { name: 'FaCoffee', icon: <FaCoffee /> },
      { name: 'FaMicrophone', icon: <FaMicrophone /> },
      { name: 'FaMusic', icon: <FaMusic /> },
      { name: 'FaLightbulb', icon: <FaLightbulb /> },
      { name: 'FaVolumeUp', icon: <FaVolumeUp /> },
      { name: 'FaVolumeDown', icon: <FaVolumeDown /> },
      { name: 'FaHeadphones', icon: <FaHeadphones /> },
      { name: 'FaPlug', icon: <FaPlug /> },
      { name: 'FaBatteryFull', icon: <FaBatteryFull /> },
      { name: 'FaBolt', icon: <FaBolt /> },
      { name: 'FaCameraRetro', icon: <FaCameraRetro /> },
      { name: 'FaCocktail', icon: <FaCocktail /> },
      { name: 'FaBroom', icon: <FaBroom /> }
      
      // ... legg til flere ikoner etter behov
    ];
  
    const handleIconClick = (iconName, e) => {
        e.stopPropagation();  // Stopper eventet fra å boble opp
        onSelect(iconName);
    }

    return (
        <div className="flex flex-wrap space-x-4">
            {icons.map((iconObj, idx) => (
                <button key={idx} type="button" onClick={(e) => handleIconClick(iconObj.name, e)}>
                {iconObj.icon}
            </button>
            ))}
        </div>
    );
};

function TilleggModal({ isOpen, onClose, onSubmit }) {
    const [tilleggData, setTilleggData] = useState({
        beskrivelse: "",
        bilde: "",
        count: 0,
        dagsPris: 30,
        maks: 2,
        navn: "",
        startPris: 99,
        id: generateRandomID(),
        status: "Active"
    });

    const handleIconSelect = (iconName) => {
        setTilleggData(prevState => ({
            ...prevState,
            bilde: iconName
        }));
        setSelectedIcon(iconName); // Oppdater valgt ikon
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTilleggData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        if (validateForm()) {
            onSubmit(tilleggData);
        }
    };

    const [selectedIcon, setSelectedIcon] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
    
        if (!tilleggData.navn.trim()) {
            errors.navn = "Navn er påkrevd.";
        }
    
        if (!tilleggData.beskrivelse.trim()) {
            errors.beskrivelse = "Beskrivelse er påkrevd.";
        }
    
        if (!tilleggData.startPris) {
            errors.startPris = "Oppstartspris er påkrevd.";
        }
    
        if (!tilleggData.dagsPris) {
            errors.dagsPris = "Pris per dag er påkrevd.";
        }
    
    
        if (!tilleggData.bilde.trim()) {
            errors.bilde = "Bilde er påkrevd.";
        }
    
        if (!tilleggData.maks) {
            errors.maks = "Maks antall er påkrevd.";
        }
    
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };


    if (!isOpen) return null;


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"> 
            <div className="bg-white p-8 rounded-md shadow-lg w-4/5 max-w-lg">
            <h2 className="bg-white p-2" >Legg til tilleggsutstyr</h2>
            <form onSubmit={(e) => handleFormSubmit(e, tilleggData)}>
            <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Navn:</label>
                        <input
                            type="text"
                            name="navn"
                            placeholder="Eks. mikrofon"
                            value={tilleggData.navn}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.navn && <p className="text-red-500 text-xs mt-1">{validationErrors.navn}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Beskrivelse:</label>
                        <input
                            type="text"
                            name="beskrivelse"
                            placeholder="Kablet og passer bra til tale ..."
                            value={tilleggData.beskrivelse}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.beskrivelse && <p className="text-red-500 text-xs mt-1">{validationErrors.beskrivelse}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Oppstartspris:</label>
                        <input
                            type="number"
                            name="startPris"
                            placeholder="99"
                            value={tilleggData.startPris}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.startPris && <p className="text-red-500 text-xs mt-1">{validationErrors.startPris}</p>}
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Pris per ekstra dag:</label>
                        <input
                            type="number"
                            name="dagsPris"
                            placeholder="30"
                            value={tilleggData.dagsPris}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.dagsPris && <p className="text-red-500 text-xs mt-1">{validationErrors.dagsPris}</p>}
                    </div>

                    <div className="mb-4">
    <label className="block mb-2 text-sm font-bold">Ikon:</label>
    <IconPicker onSelect={handleIconSelect} />
</div>
    
                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Bilde:</label>
                        <input
                            type="text"
                            name="bilde"
                            placeholder="https://storage.googleapis.com/partylyd2022.appspot.com/images/soundboksNY%20(1)%20(2)-min.png"
                            value={tilleggData.bilde}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.bilde && <p className="text-red-500 text-xs mt-1">{validationErrors.bilde}</p>}
                    </div>
    
                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold">Maks antall på én bestilling:</label>
                        <input
                            type="number"
                            name="maks"
                            placeholder="2"
                            value={tilleggData.maks}
                            onChange={handleChange}
                            className="w-full p-2 border rounded"
                        />
                        {validationErrors.maks && <p className="text-red-500 text-xs mt-1">{validationErrors.maks}</p>}
                    </div>
    
                    <div className="flex items-center">
    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2">Legg til Tillegg</button>
    <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-2">Lukk</button>
    <div className="flex justify-center items-center w-10 h-10 p-2 bg-orange-300 rounded-lg ml-auto">
        {/* Vis valgt ikon basert på navnet */}
        {tilleggData.bilde === 'FaBeer' && <FaBeer />}
        {tilleggData.bilde === 'FaCoffee' && <FaCoffee />}
        {tilleggData.bilde === 'FaMicrophone' && <FaMicrophone />}
        {tilleggData.bilde === 'FaMusic' && <FaMusic />}
        {tilleggData.bilde === 'FaLightbulb' && <FaLightbulb />}
        {tilleggData.bilde === 'FaVolumeUp' && <FaVolumeUp />}
        {tilleggData.bilde === 'FaVolumeDown' && <FaVolumeDown />}
        {tilleggData.bilde === 'FaHeadphones' && <FaHeadphones />}
        {tilleggData.bilde === 'FaPlug' && <FaPlug />}
        {tilleggData.bilde === 'FaBatteryFull' && <FaBatteryFull />}
        {tilleggData.bilde === 'FaBolt' && <FaBolt />}
        {tilleggData.bilde === 'FaCameraRetro' && <FaCameraRetro />}
        {tilleggData.bilde === 'FaCocktail' && <FaCocktail />}
        {tilleggData.bilde === 'FaBroom' && <FaBroom />}
    </div>
</div>
                    
                </form>
            </div>
        </div>
    );}

    

    function AddTillegg({ supplierId }) {
        const [isModalOpen, setIsModalOpen] = useState(false);
        
    
        const handleSubmit = async (tilleggData) => {

        // Hent eksisterende dokument basert på ID
        const supplierRef = doc(db, 'suppliers', supplierId);
        const supplierSnap = await getDoc(supplierRef);

        if (!supplierSnap.exists()) {
            console.error("Finner ikke leverandørdokument med gitt ID.");
            return;
        }

        // Oppdater dokumentet ved å legge til nytt 'tillegg' til arrayen
        await updateDoc(supplierRef, {
            tillegg: arrayUnion(tilleggData)
        });

        console.log('Tillegg lagt til!');
        setIsModalOpen(false);
        window.location.reload();
        
    };

    return (
        <div className="flex justify-center py-10">
            <button className="bg-orange-500 hover:bg-orange-600 w-8 h-8 text-2xl text-white rounded transition duration-300" onClick={() => setIsModalOpen(true)}>+</button>
            <TilleggModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                onSubmit={handleSubmit}  // Endret denne for å fjerne unødvendige parametre
            />
        </div>
    );
}

export default AddTillegg;