// @ts-nocheck
import { Key, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { Button, Card, Col, Container, Row, Form } from "react-bootstrap";

import Loading from "../components/Loading.tsx";
import { app } from "../firebase1.ts";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { updateDoc, arrayUnion, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase1.ts";
import { AiOutlineQuestionCircle } from "react-icons/ai"; //question mark
import { AiOutlineCheckCircle } from "react-icons/ai"; //checkmark
import { MdOutlineCancel } from "react-icons/md"; //cancel
import { IconContext } from "react-icons";
import OrderList from "../components/OrderList.tsx";
import ModalComp from "../components/ModalComp.tsx";

const OrderDetail = () => {
  let orderId = useParams();
  const supplierId = useState(localStorage.getItem("supplierIdPL") || "");

  // Firebase functions
  const functions = getFunctions(app, 'europe-west1');
  const alertSupplierChat = httpsCallable(functions, "alertSupplierChat");

  // Data about order
  const [order, setOrder] = useState(null);
  const [addonsForOrder, setAddonsForOrder] = useState(null);
  const [commentsOnOrder, setCommentsOnOrder] = useState([]);
  const [comment, setComment] = useState();

  // Data about supplier
  const [supplierName, setSupplierName] = useState();
  const [supplierPhone, setSupplierPhone] = useState();
  const [supplierAddress, setSupplierAddress] = useState();
  const [supplierZip, setSupplierZip] = useState();
  const [supplierCity, setSupplierCity] = useState();

  const [titlePackage, setTitlePackage] = useState();
  const [startPrice, setStartPrice] = useState(0);
  const [extraDayPrice, setExtraDayPrice] = useState(0);
  const [descriptionPackage, setDescriptionPackage] = useState("");
  const [imagePackage, setImagePackage] = useState([]);
  const [addonEquiptmentPackage, setAddonEquiptmentPackage] = useState([]);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  async function fetchData(id: string) {
    const docRef = doc(db, "supplierSales", id);
    const list: SetStateAction<DocumentData[]> = [];
    try {
      const doc = await getDoc(docRef);
      const data = doc.data();
      setOrder(data);
      fetchSupplier(data.supplierId, data.kategori, data.valgtTilleggsUtstyr);
      if (data.kommentarKunde) {
        data.kommentarKunde.forEach((comment) => {
          list.push(comment);
        });
        await setCommentsOnOrder(list);
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  async function fetchSupplier(
    id: string,
    categoryFromSale: string,
    chosenExtraEquiptment: any
  ) {
    const docRef = doc(db, "suppliers", id);
    console.log(chosenExtraEquiptment);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let data = docSnap.data();
        if (data.packages) {
          const filteredPackages = data.packages.filter(
            (pck) => pck.kategori === categoryFromSale
          );
          console.log(data);
          if (filteredPackages.length > 0) {
            setTitlePackage(filteredPackages[0].navn);
            setStartPrice(filteredPackages[0].startPris);
            setExtraDayPrice(filteredPackages[0].dagsPris);
            setDescriptionPackage(filteredPackages[0].beskrivelse);
            setImagePackage(filteredPackages[0].bilder);
            setAddonEquiptmentPackage(filteredPackages[0].tillegg);
          }
          setSupplierName(data.name);
          setSupplierAddress(data.address);
          setSupplierPhone(data.contacts[0].phone);
          setSupplierZip(data.zipcode);
          setSupplierCity(data.city);
        }
        if (data.tillegg && chosenExtraEquiptment) {
          const addonsForOrder = data.tillegg.filter(
            (item) => chosenExtraEquiptment[item.navn] !== undefined
          );
          addonsForOrder.forEach(
            (item) => (item.count = chosenExtraEquiptment[item.navn])
          );
          setAddonsForOrder(addonsForOrder);
          console.log(addonsForOrder);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function addCommentToOrder() {
    let orderID = orderId.orderId;
    let comments = {
      kommentar: comment,
      tidspunkt: new Date(),
      kommentator: "kunde",
    };

    let data = {
      client_reference_id: orderID,
      message: comments.kommentar,
      sentBy: comments.kommentator,
    };

    const docRef = doc(db, "supplierSales", orderID);
    await updateDoc(docRef, {
      kommentarKunde: arrayUnion(comments),
    })
      .then((e) => {
        alertSupplierChat(data)
        fetchData(orderId.orderId)
        setComment("")
      
      })
      .catch((e) => {
        console.log("Error getting document:", e);
      });
  }

  // useEffects
  useEffect(() => {
    if (orderId.orderId) {
      let id = orderId.orderId;
      fetchData(id);
    }
  }, [orderId.orderId]);

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const handleCloseAllModals = () => {
      setShowModal1(false);
      setShowModal2(false);
      setShowModal3(false);
  };
  return (
    <div>
      {order === null ? (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
          }}
        >
          <Loading color={"#e57e22"} />
        </Col>
      ) : (
        <>
          <Container>
            <>
              <Card className="text-black bg-white text-left w-12/12 mt-6 shadow-md border-gray pl-2 md:mr-5">
                <Card.Body className="pt-4 pr-1 pl-1 pb-2.5 ">
                  <>
                    <Row>
                      <Col
                        md={{ span: 1, offset: 0 }}
                        xs={{ span: 1, offset: 4 }}
                        className="lg:pl-8 lg:pb-0 md:pl-8 md:pb-0 pl-5 pb-2"
                      >
                        {order.status === "Ikke bekreftet" ? (
                          <IconContext.Provider
                            value={{ color: "#6495ed", size: "54px" }}
                          >
                            <div>
                              <AiOutlineQuestionCircle />
                            </div>
                          </IconContext.Provider>
                        ) : null}
                        {order.status === "Bekreftet" ? (
                          <div>
                            <IconContext.Provider
                              value={{ color: "#94CD74", size: "54px" }}
                            >
                              <div>
                                <AiOutlineCheckCircle />
                              </div>
                            </IconContext.Provider>
                          </div>
                        ) : null}
                        {order.status === "Kansellert" ? (
                          <div>
                            <IconContext.Provider
                              value={{ color: "#DA271B", size: "54px" }}
                            >
                              <div>
                                <MdOutlineCancel />
                              </div>
                            </IconContext.Provider>
                          </div>
                        ) : null}
                      </Col>
                      <Col className="md:-ml-5" md={{ span: 4, offset: 0 }}>
                        <div className="text-xs">STATUS PÅ BESTILLING</div>
                        <div className="text-lg mt-2 md:mb-0 mb-2">
                          {order.status}
                        </div>
                      </Col>

                      <Col className="" md={{ span: 4, offset: 0 }}>
                        {order.tid.totalDays === 0 ? (
                          <>
                            <div className="text-xs">DATO</div>
                            <div className="text-lg mt-2 md:mb-0 mb-2">
                              {capitalize(
                                new Date(
                                  order.tid.startDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs">DATO FRA OG TIL</div>
                            <div className="text-lg mt-2 md:mb-0 mb-2">
                              {capitalize(
                                new Date(
                                  order.tid.startDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}{" "}
                              -{" "}
                              {new Date(
                                order.tid.endDate.seconds * 1000
                              ).toLocaleString(`no-NO`, options)}
                            </div>
                          </>
                        )}
                      </Col>
                      <Col className="" md={{ span: 3, offset: 0 }}>
                        <div className="text-xs">HENTE SELV HOS</div>
                        <div className="text-lg mt-2 md:mb-0 mb-2">
                          <div>
                            <div className="text-md italic">
                              {supplierAddress}, {supplierZip} {supplierCity}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {order.status === "Ikke bekreftet" ? (
                        <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#6495ed]" />
                      ) : null}
                      {order.status === "Bekreftet" ? (
                        <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#94CD74]" />
                      ) : null}
                      {order.status === "Kansellert" ? (
                        <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#DA271B]" />
                      ) : null}
                    </Row>
                  </>
                </Card.Body>
              </Card>
              <>
                <Row md={2}>
                  <Col className="pt-10 pb-10">
                    <Row>
                      <Col md={7}>
                        <div className="font-normal text-3xl tracking-wider whitespace-pre pt-2.5">
                          Din bestilling <button onClick={() => { handleCloseAllModals(); setShowModal1(true); }}><AiOutlineQuestionCircle size={20} color="gray" /></button>
                          {showModal1 && <ModalComp title="Hvordan fungerer bestilling her?" body={<p>Du har nå sendt forespørsel om å leie via PartyLyds leverandørnettverk. På denne siden får du oversikt, og kan samhandle med leverandøren som er tilknyttet din bestilling. Alle bestillinger skal bekreftes eller annuleres i løpet av 24 timer, så bare vent til din bestilling blir bekreftet, eller ta kontakt. Leverandørene kan ha ulike betingelser til leie av utstyr, så vær oppmerksom på hva som gjelder for din leie.</p>} closeModal={() => setShowModal1(false)} show={showModal1} hide={handleCloseAllModals} />}
                        </div>
                       
                      </Col>
                    </Row>
                    <Card className="text-black bg-white text-left w-12/12 mt-6 shadow-md border-gray pl-2">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          <div className="text-xl pb-4 pt-2 tracking-widest">
                            {titlePackage}
                          </div>
                          <div className="pr-10">
                            Din bestilling må hentes{" "}
                            <strong>
                              {capitalize(
                                new Date(
                                  order.tid.startDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}
                            </strong>{" "}
                            og leveres tilbake i løpet av{" "}
                            <strong>
                              {capitalize(
                                new Date(
                                  order.tid.endDate.seconds * 1000
                                ).toLocaleString(`no-NO`, options)
                              )}
                            </strong>
                            .
                          </div>
                          <Row>
                            <Col>
                              <Card className="pt-4 pl-2 mb-2 rounded-xl border-none">
                                <OrderList
                                  //@ts-ignore
                                  bilde={imagePackage}
                                  //@ts-ignore
                                  beskrivelse={descriptionPackage}
                                  //@ts-ignore
                                  navn={titlePackage}
                                  //@ts-ignore
                                  count={1}
                                />
                                {addonsForOrder ? (
                                  <>
                                    {addonsForOrder.map((addon) => {
                                      return (
                                        <>
                                          {addon.count > 0 ? (
                                            <OrderList
                                              //@ts-ignore
                                              bilde={addon.bilde}
                                              //@ts-ignore
                                              beskrivelse={addon.beskrivelse}
                                              //@ts-ignore
                                              navn={capitalize(addon.navn)}
                                              //@ts-ignore
                                              count={addon.count}
                                            />
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : null}
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="pb-2 lg:pl-10 lg:pr-8">
                    <div className="pb-2 lg:pt-10 font-normal text-3xl tracking-wider whitespace-pre">
                      Leverandørchat <button onClick={() => { handleCloseAllModals(); setShowModal2(true); }}><AiOutlineQuestionCircle size={20} color="gray" /></button>
                      {showModal2 && <ModalComp title="Hvordan får jeg kontakt med leverandør?" body={<p>Nå som du skal leie utstyr fra en av PartyLyd sine leverandører bør du holde kontakten direkte her. Ved å sende melding på bestillingssiden kan dere kommunisere på en ryddig måte, og begge parter får varsler ved nye meldinger. Har du problemer med å få kontakt? Ta direkte kontakt med din leverandør på <strong>{supplierPhone}</strong></p>} closeModal={() => setShowModal2(false)} show={showModal2} hide={handleCloseAllModals} />}
                    </div>
                    <Card className="text-black bg-white text-left mt-6 shadow-md border-gray pl-2">
                      <Card.Body className="pt-2 pr-3 pl-1 pb-4">
                        {commentsOnOrder.length > 0 ? (
                          <div>
                            {commentsOnOrder.map((comment) => {
                              return (
                                <>
                                  {comment.kommentar !== "" ? (
                                    <>
                                      {comment.kommentator === "kunde" ? (
                                        <div className="lg:w-10/12 lg:float-right p-2 border-pl_orange text-white rounded-xl mb-2 bg-pl_orange flex">
                                          {" "}
                                          <div className="float-left w-9/12 lg:w-12/12">
                                            {comment.kommentar}
                                          </div>
                                          <div className="text-sm float-right">
                                            {new Date(
                                              comment.tidspunkt.seconds * 1000
                                            ).toLocaleDateString(`no-NO`)}
                                            ,{" "}
                                            {new Date(
                                              comment.tidspunkt.seconds * 1000
                                            ).toLocaleTimeString(`no-NO`, {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="lg:w-10/12  p-2 border-beige border-2 text-beige rounded-xl mb-2 bg-white lg:float-left flex">
                                          {" "}
                                          <div className="float-left font-bold w-9/12 lg:w-12/12">
                                            {comment.kommentar}
                                          </div>
                                          <div className="text-sm float-right">
                                            {new Date(
                                              comment.tidspunkt.seconds * 1000
                                            ).toLocaleDateString(`no-NO`)}
                                            ,{" "}
                                            {new Date(
                                              comment.tidspunkt.seconds * 1000
                                            ).toLocaleTimeString(`no-NO`, {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                        <textarea
                          type="text"
                          className="w-full h-18 border border-gray-400 rounded px-3 py-2 outline-none text-gray-600 mt-3"
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Ny melding"
                        />
                        <Button
                          className="float-left lg:mt-2 tracking-wide h-10"
                          style={{
                            backgroundColor: "#e57e22",
                            border: "none",
                            fontWeight: 500,
                          }}
                          onClick={() => addCommentToOrder()}
                        >
                          Send melding
                        </Button>
                      </Card.Body>
                    </Card>

                    <div className="pb-2 pt-10 font-normal text-3xl tracking-wider whitespace-pre">
                      Pris og betaling <button onClick={() => { handleCloseAllModals(); setShowModal3(true); }}><AiOutlineQuestionCircle size={20} color="gray" /></button>
                      {showModal3 && <ModalComp title="Hvordan funger betaling?" body={<p>På denne siden får du oversikt over din bestilling, men selve betalingen skjer direkte mellom deg og leverandøren. Det er mange som bruker vipps eller lignende løsninger, og dette avtaler du enkelt ved å sende melding til din leverandør. Har du problemer så vil enten leverandøren eller PartyLyd hjelpe deg videre.</p>} closeModal={() => setShowModal3(false)} show={showModal3} hide={handleCloseAllModals} />}
                    </div>
                    <div className="text-sm tracking-wider pt-2  text-black">
                      Her ser du oppsummering på betaling - <strong>dette avtales direkte
                      med leverandør.</strong>
                    </div>
                    <Card className="text-black bg-white text-left w-12/12 mt-2 shadow-md border-gray pl-2 mb-10">
                      <Card.Body className="pt-0 pr-1 pl-1 pb-0">
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          <div>
                            <Row md={1} className="p-4">
                              <Col xs={6} md={8}>
                                <div className="tracking-widest">
                                  <div>Pakkepris</div>
                                </div>
                              </Col>
                              <Col xs={6} md={4}>
                                <div className="tracking-widest text-right mr-2">
                                  {order.tid.totalDays <= 1
                                    ? Number(startPrice)
                                    : Number(startPrice) +
                                      (Number(order.tid.totalDays-1) * Number(extraDayPrice))}
                                  ,-
                                </div>
                              </Col>
                            </Row>
                            {order.tilleggsPris ? (
                              <>
                                <hr />
                                <Row md={1} className="p-4">
                                  <Col xs={6} md={8}>
                                    <div className="tracking-widest">
                                      <div>Tilleggspris</div>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={4}>
                                    <div className="tracking-widest text-right mr-2">
                                      {order.tilleggsPris},-
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ) : null}

                            <div className="border-1 "></div>
                            <Row md={1} className="p-4">
    <Col xs={6} md={8}>
        <div className="tracking-widest">
            <div>Totalpris</div>
            <div className="text-xs text-[#808080]">
                (Totalsum inkludert 25% MVA)
            </div>
        </div>
    </Col>
    <Col xs={6} md={4}>
        <div className="tracking-widest text-right mr-2">
            {order.totalPris}
            ,-
        </div>
    </Col>
</Row>
<div className="border-2 "></div>
<Row md={1} className="p-2 italic">
    <Col xs={6} md={8}>
        <div className="tracking-widest">
            <div>Ordre ID</div>
        </div>
    </Col>
    <Col xs={6} md={4}>
        <div className="tracking-widest text-right mr-2">
            {orderId.orderId?.slice(0,6)}
        </div>
    </Col>
</Row>
                            <div></div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            </>
          </Container>
        </>
      )}
    </div>
  );
};

export default OrderDetail;
