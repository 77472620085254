import { useEffect, useState } from "react";
import { Button, Col, Row, Container, Form } from "react-bootstrap";
import {
  deleteDoc,
  arrayUnion,
  doc,
  updateDoc,
  getDoc,
  arrayRemove,
} from "firebase/firestore";
// @ts-ignore
import { db } from "../firebase1.ts";
// @ts-ignore
import ModalComp from "../components/ModalComp.tsx";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../style/alerts.css";

const AdminSupplierMessageService = () => {
  const supplierId = localStorage.getItem("supplierIdPL") || "";

  // Message rules state
  const [messageRules, setMessageRules] = useState<
    { message: string; daysAhead: string; category: string }[]
  >([]);
  const [categories, setCategories] = useState([]);
  const [messageQuota, setMessageQuota] = useState(0);

  // New message
  const [message, setMessage] = useState("");
  const [daysAhead, setDaysAhead] = useState("");
  const [categoryPackage, setCategoryPackage] = useState("");

  const handleChange = (e: any) => {
    if (e.target.value.length <= 300) {
      setMessage(e.target.value);
    }
  };

  const [showModal, setShowModal] = useState(false);

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  useEffect(() => {
    const fetchMessageRules = async () => {
      const list: any = [];
      const docRef = doc(db, "suppliers", supplierId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.messageQuota === 0) {
          setShowModal(true);
        }
        setMessageQuota(data.messageQuota);
        setMessageRules(data.messageRules || []);

        data.packages.map((pck: any) => {
          list.push(pck.kategori);
        });
        await setCategories(list);
      }
    };
    fetchMessageRules();
  }, [supplierId]);

  // Update logic to check for max 3 rules
  async function addMessageServiceRule() {
    if (messageRules.length >= 3) {
      toast.error(
        "Du kan maksimalt ha 3 regler. Slett en regel før du legger til en ny",
        {
          position: "top-left",
          className: "alert-toast",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return;
    }

    if (daysAhead === "") {
      toast.error("Du må velge antall dager før varsling", {
        position: "top-left",
        className: "alert-toast",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    let messageRule = {
      message: message,
      daysAhead: daysAhead,
      category: categoryPackage,
      active: true,
    };

    const docRef = doc(db, "suppliers", supplierId);
    await updateDoc(docRef, {
      messageRules: arrayUnion(messageRule),
    })
      .then((e) => {
        setMessage("");
        setDaysAhead("");
        setMessageRules((prev) => [...prev, messageRule]);
      })
      .catch((e) => {
        console.log("Error getting document:", e);
      });
  }

  async function deleteMessageRule(index: any) {
    const messageRuleToDelete = messageRules[index];
    const docRef = doc(db, "suppliers", supplierId);
    await updateDoc(docRef, {
      messageRules: arrayRemove(messageRuleToDelete),
    })
      .then(() => {
        setMessageRules((prev) => prev.filter((_, i) => i !== index));
      })
      .catch((e) => {
        console.log("Error deleting document:", e);
      });
  }

  function refillMessageQuota() {
    // handle buying more messages here
    setShowModal(false);
  }

  return (
    <Container>
      <div
        className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0"
        style={{ color: "#24292e" }}
      >
        Meldingstjeneste
      </div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Row>
        <Col className="lg:pl-32 lg:pt-6 mb-8" lg={5}>
          <div
            className="font-medium text-xl tracking-wider pb-4"
            style={{ color: "#24292e" }}
          >
            Ny regel
          </div>
          <div
            className="italic tracking-wide text-sm pb-1"
            style={{ color: "#6b7280" }}
          >
            Opprett ny regel for utsending av melding til kunder.
          </div>

          <Form>
            <Form.Control
              as="select"
              aria-label="Velg pakke"
              //onClick={onClick}
              onChange={(e) => setCategoryPackage(e.target.value)}
              className="mt-2 px-2 font-bold "
              value={`${categoryPackage}`}
            >
              <option value={""}>Alle pakker </option>
              {categories.map((category) => (
                <option value={category}>{capitalize(category)}</option>
              ))}
            </Form.Control>

            <Form.Control
              as="select"
              aria-label="Velg antall dager før"
              //onClick={onClick}
              onChange={(e) => setDaysAhead(e.target.value)}
              className="mt-2 px-2 font-bold "
              value={`${daysAhead}`}
            >
              <option value={""}>Antall dager før </option>
              <option value={0}> Samme dag</option>
              <option value={1}> 1</option>
              <option value={2}> 2</option>
              <option value={3}> 3</option>
              <option value={4}> 4</option>
              <option value={5}> 5</option>
              <option value={6}> 6</option>
              <option value={7}> 7</option>
            </Form.Control>

            <Form.Group className="mt-2 mb-1" controlId="form.CancelReason">
              <Form.Control
                as="textarea"
                value={message}
                onChange={handleChange}
                rows={3}
              />
              <div
                style={{ textAlign: "right" }}
              >{`${message.length}/300`}</div>
            </Form.Group>

            <Button
              className="tracking-wide h-10 lg:mt-2"
              onClick={() => addMessageServiceRule()}
              //disabled={!endDate}
              style={{
                backgroundColor: "#212529",
                border: "none",
                fontWeight: 500,
              }}
            >
              <div className="tracking-wide flex text-sm">
                <div className="">Opprett regel </div>
              </div>
            </Button>
          </Form>
        </Col>
        <Col className="lg:pl-16 lg:pr-28 lg:pt-6 " lg={7}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="font-medium text-xl tracking-wider pb-4"
              style={{ color: "#24292e" }}
            >
              Andre regler
            </div>

            <div
              style={{ textAlign: "right", color: "#707070", lineHeight: "1" }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                }}
              >
                meldinger igjen
              </div>
              <div
                style={{
                  fontSize: "48px",
                  fontWeight: "700",
                  color: "#24292e",
                }}
              >
                {messageQuota}
              </div>
            </div>
          </div>

          <div
            className="italic tracking-wide text-sm pb-1"
            style={{ color: "#6b7280" }}
          >
            Maks 3 regler. Meldinger blir sendt ut kl 09:00.
          </div>

          <div className="mb-6">
            <table className="text-left w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b-2 border-gray-400">
                    Kategori
                  </th>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b-2 border-gray-400">
                    Melding
                  </th>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b-2 border-gray-400">
                    Forvarsel
                  </th>
                  <th className="py-4 px-6 bg-grey-lightest font-bold uppercase text-sm text-grey-dark border-b-2 border-gray-400">
                    Slett
                  </th>
                </tr>
              </thead>
              <tbody>
                {messageRules.map((rule, index) => (
                  <tr className="hover:bg-grey-lighter" key={index}>
                    <td className="py-4 px-6 border-t border-gray-200">
                      {rule.category === "" || !rule.category ? (
                        <div>Alle</div>
                      ) : (
                        <>{capitalize(rule.category)}</>
                      )}
                    </td>
                    <td className="py-4 px-6 border-t border-gray-200">
                      {rule.message}
                    </td>
                    <td className="py-4 px-6 border-t border-gray-200">
                      {rule.daysAhead === "0" ? (
                        <div>Samme dag</div>
                      ) : (
                        <>{rule.daysAhead}</>
                      )}
                    </td>

                    <td className="py-4 px-6 border-t border-gray-200">
                      <button
                        className="text-white font-bold py-2 px-4 rounded bg-red-500 hover:bg-red-700"
                        onClick={() => deleteMessageRule(index)}
                      >
                        Slett
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <ModalComp
        show={showModal}
        title="Du har brukt opp meldingskvoten din"
        closeModal={() => setShowModal(false)}
        hide={() => setShowModal(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={() => setShowModal(false)}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Du har nå brukt opp meldingskvoten din. Ønsker du å kjøpe mer?
            <Row className="mt-2 mb-2">
              <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                <Button
                  variant="primary"
                  className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12 ml-2"
                  onClick={() => refillMessageQuota()} // function to refill message quota
                >
                  Ja
                </Button>

                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                  onClick={() => setShowModal(false)}
                >
                  Nei
                </Button>
              </Col>
            </Row>
          </>
        }
      />
    </Container>
  );
};

export default AdminSupplierMessageService;
