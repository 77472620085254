import { FC } from "react";
import { Row, Col } from "react-bootstrap";

interface StripeProps {
  name?: string;
  image?: string;
  desc?: string;
  num?: number;
}

const Stripe: FC<StripeProps> = ({
  name = "",
  image = "",
  desc = "",
  num,
}) => {
  return (
    <div>
     Dette funka faktisk med Stripe også
    </div>
  );
};

export default Stripe;