import { FC } from "react";
import { Card } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "../style/icons.css";
import { BiCurrentLocation } from "react-icons/bi";

interface SupplierCategoryCardProps {
  supplierId: string;
  categoryName: string;
  name: string;
  image: string;
  price: number;
  location: string;
  idPackage: string;
  distance?: number | null;  // Add this line
}

const SupplierCategoryCard: FC<SupplierCategoryCardProps> = ({
  supplierId,
  categoryName,
  name,
  image,
  price,
  location,
  idPackage,
  distance, 
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

    return (
      <div className="tracking-wide font-light">
        <Col >  
        <Card className="md:px-0 px-1 py-1 pb-3 mb-2 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigateToOtherSite(`/leverandor/${supplierId}/${categoryName}/${idPackage}`)
            }
          >
            <Card.Img
            
              variant="top"
              src={image}
              style={{
                width: "100%",
                height: "28vh",
                objectFit: "cover",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "10px",
              }}
            />
            <Container>
              <Row>
                <Col>
                  <div className="font-bold text-small text-lg tracking-wider mb-2 mt-2">
                    {name}
                  </div>
                </Col>
              </Row>
              <Row>
              <Col md={6} xs={6} className="md:font-small font-medium" style={{ marginLeft: 'auto' }}>
                  Pris fra {price},-
                </Col>
                <Col md={6} xs={6}>
  <div className="flex flex-col">
    <div className="flex font-medium">
      <BiCurrentLocation size={21} className="mt-0.5 mr-1" />
      {location}
    </div>
    {distance !== undefined && distance !== null && (
      <p className="md:font-small font-medium">{Math.round(distance)} km unna</p>
    )}
  </div>
</Col>
              </Row>
            </Container>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default SupplierCategoryCard;
