import GoogleMapReact from "google-map-react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { FC } from "react";
import { FaMapMarker } from 'react-icons/fa';

const AnyReactComponent = ({ onClick }) => (
    <div
      onClick={onClick}
      style={{
        fontSize: "2rem",
        transform: "translate(-50%, -50%)",
        cursor: "pointer", // to show it's clickable
      }}
    >
      <FaMapMarker color="#f7b710" />
    </div>
  );

interface LocationProps {
  style?: any;
  color?: string;
  locations?: {
    lat: number;
    lng: number;
    text: string;
  }[];
}

const MapSupplier: FC<LocationProps> = ({ color, locations = [] }) => {
  const defaultProps = {
    center: {
      lat: 61.23832,
      lng: 8.5,
    },
    zoom: 6,
  };

  return (
    <div className="mb-10">

        <div className="pt-2 md:pb-12">
    <div style={{ height: "75vh", marginRight: "30px", borderRadius: "10px", overflow: "hidden" }}>
    <GoogleMapReact
  defaultCenter={defaultProps.center}
  defaultZoom={defaultProps.zoom}
>
  {locations.map((loc, index) => (
    <AnyReactComponent
      key={index}
      lat={loc.lat}
      lng={loc.lng}
      onClick={() => {
        // Using the structure: /leverandor/{supplierId}/{category}/{packageId}
        window.location.href = `/leverandor/${loc.supplierId}/${loc.category}/${loc.packageId}`; 
      }}
    />
  ))}
</GoogleMapReact>
    </div>
</div>
    </div>
  );
};
export default MapSupplier;