import { Modal } from "react-bootstrap";
import React, { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  title?: string;
  body?: any;
  show?: any;
  hide?: any;
  footer?: any;
  size?: any;
  closeModal?: () => void;
}

const ModalComp: FC<ModalProps> = ({
  title = "",
  body,
  show,
  hide,
  footer = "",
  size = "lg",
  closeModal
}) => {
  return (
    <>
      <Modal
        size={size}
        show={show}
        onHide={hide}
        centered
        className="font-josefin rounded-xl"
        contentClassName="bg-white rounded-xl"
        style={{}}
      >
        <div className="relative px-8 pt-4 pb-2">
          <div className="text-2xl tracking-wider">{title}</div>
          <button
            onClick={closeModal}
            className="absolute top-4 right-5 text-2xl hover:text-gray-500 hover:bg-white"
            aria-label="Close modal"
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="px-8 py-6">{body}</div>
        {footer && (
         <div className="flex items-center justify-end px-8 py-4">
            {footer}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalComp;
