// @ts-nocheck
import { Col, Container, Row } from "react-bootstrap";
import pl_andreas from "../img/pl_andreas.png";
import pl_thor from "../img/pl_thor.png";
import pl_gunnar from "../img/pl_gunnar.png";

import andreas_kontakt_oss from "../img/andreas_kontakt_oss.jpg";
import steven_kontakt_oss from "../img/steven_kontakt_oss.jpg";
import oda_kontakt_oss from "../img/oda_kontakt_oss.jpg";
import havard_kontakt_oss from "../img/havard_kontakt_oss.jpg";
import emil_kontakt_oss from "../img/emil_kontakt_oss.jpg";
import jonas_kontakt_oss from "../img/jonas_kontakt_oss.jpg";
import martin_kontakt_oss from "../img/martin_kontakt_oss.jpg";
import thor_kontakt_oss from "../img/thor_kontakt_oss.jpeg";

const Contact = () => {
  return (
    <div className="mb-10 font-josefin ">
      <Container>

        <Row className="justify-content-md-center mt-8">
          <Col md={{ span: 5, offset: 0 }} className=" px-4 lg:ml-10 lg:mt-8">
            <div className="font-bold text-3xl tracking-wider pb-4">
              {" "}
              Kontakt oss{" "}
            </div>
            <div>
              Vi er her for å hjelpe deg! Vi hjelper deg dersom du har problemer
              med din bestilling, betaling eller montering. Vi kan også hjelpe
              deg med masse annet! Lurer du på noe, ta kontakt!
              <br></br>
              <div className=" mt-3 font-normal">
                Bedrift eller organisasjon? Vi tilbyr faktura som betalingsmåte ved utsjekk.
              </div>
            </div>
            <br></br>
            <div className="text-2xl font-medium mb-2">Epost</div>
            <div>
              {" "}
              Du kan ta kontakt med oss på epost til{" "}
              <strong>kontakt@partylyd.no</strong>. Dersom du kontakter oss
              vedrørende en bestilling, ber vi deg bruke ordrenummeret i
              emnefeltet.{" "}
            </div>
            <br></br>
            <div className="md:px-6">
              Epost: <strong>kontakt@partylyd.no</strong>
            </div>
            <br></br>
            <div className="text-2xl font-medium mb-2">Telefon</div>
            <div>
              {" "}
              Dersom du kontakter oss vedrørende en bestilling, eller du har
              spørsmål om levering eller montering, ring nummeret som står
              under.
            </div>
            <br></br>
            <div className="md:px-6">
              Telefon: <strong>61 60 94 90</strong>
            </div>
          </Col>

          <Col className=" lg:pb-10 mb-10 lg:pl-10 lg:pr-10" lg={6}>
            <Row>
              <Col lg={{ offset: 0 }} className="g-4">
                <img
                  src={andreas_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="font-montserrat font-extrabold">
                    Andreas Torkildsen Hjertaker
                  </div>
                  <div className="pt-2 font-montserrat">
                    Teknisk leder
                  </div>
                  {/* <div className="pt-2 font-montserrat ">
                    andreas@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 414 96 901</div> */}
                </div>
              </Col>
              <Col lg={{ offset: 0 }} className="g-4">
                <img
                  src={havard_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="font-montserrat font-extrabold">
                    Håvard Hellang
                  </div>
                  <div className="pt-2 font-montserrat">
                    Daglig leder
                  </div>
                  {/* <div className="pt-2 font-montserrat ">
                    haavard@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 466 80 099</div> */}
                </div>
              </Col>
              <Col lg={{ offset: 0 }} className="g-4">
                <img
                  src={steven_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="font-montserrat font-extrabold">
                    Steven Xu
                  </div>
                  <div className="pt-2 font-montserrat">
                    Produktleder
                  </div>
                  {/* <div className="pt-2 font-montserrat ">
                    steven@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 913 11 636</div> */}
                </div>
              </Col>
            </Row>
            <Row>
            <Col lg={{ offset: 0 }} className="g-4">
              <img
                src={emil_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="font-montserrat font-extrabold">
                  Emil Bjørlykke Berglund
                </div>
                <div className="pt-2 font-montserrat">
                  Utvikler
                </div>
                {/* <div className="pt-2 font-montserrat ">
                  emil@kasserolle.no
                </div>
                <div className=" font-montserrat">+47 902 49 601</div> */}
              </div>
            </Col>
            <Col lg={{ offset: 0 }} className="g-4">
              <img
                src='https://static.wixstatic.com/media/7d4c7a_630bcecf18a04ceb810eecbe3db87ae9~mv2.jpg/v1/crop/x_208,y_0,w_1716,h_2277/fill/w_410,h_544,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_9691_2%20(1).jpg'
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="font-montserrat font-extrabold">
                Nanna Gleditsch 
                </div>
                <div className="pt-2 font-montserrat">
                  UX / UI
                </div>
                {/* <div className="pt-2 font-montserrat ">martin@kasserolle.no</div>
                <div className=" font-montserrat">+47 46819933</div> */}
              </div>
            </Col>
            <Col lg={{ offset: 0 }} className="g-4">
              <img
                src={thor_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="font-montserrat font-extrabold">
                  Thor Holtung
                </div>
                <div className="pt-2 font-montserrat">
                  Strategi og Marked
                </div>
                {/* <div className="pt-2 font-montserrat ">
                  thor@kasserolle.no
                </div>
                <div className=" font-montserrat">+47 97078176</div> */}
              </div>
            </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
