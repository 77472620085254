import { FC } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface DamageProps {
  lager?: string;
  utstyr?: string;
  beskrivelse?: string;
  tid?: number;
  sms?: boolean;
  tlf?: string;
  ordreId?: string;
}

function capitalize(s: string) {
  return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
}

const Damage: FC<DamageProps> = ({
  lager = "",
  utstyr = "",
  beskrivelse = "",
  tid = 0,
  ordreId = "",
  sms = false,
  tlf = "",
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col xs={2} md={2}>
            <div className="text-md pt-2">{capitalize(lager)}</div>
          </Col>
          <Col xs={2} md={2}>
            <div className="text-md pt-2">{capitalize(utstyr)}</div>
          </Col>
          <Col xs={2} md={2}>
            <div className="text-md pt-2">{capitalize(beskrivelse)}</div>
          </Col>
          <Col xs={1} md={1}>
            <div className="text-md pt-2 pl-2">{sms ? "Ja" : "Nei"}</div>
          </Col>
          <Col xs={2} md={2}>
            <div className="text-md pt-2 pl-2">{tlf.slice(3, 11)}</div>
          </Col>
          <Col xs={1} md={1}>
            <div
              className="text-md pt-2 font-bold cursor-pointer"
              onClick={() => navigateToOtherSite(`/order/${ordreId}`)}
            >
              {ordreId.slice(0, 5)}
            </div>
          </Col>
          <Col xs={2} md={2}>
            <div className="text-md pt-2">
              {new Date(tid * 1000).toLocaleString(`no-NO`)}
            </div>
          </Col>
        </Row>
        <hr
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "100%",
            float: "right",
            marginTop: "1rem",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default Damage;
