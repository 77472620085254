import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase1.ts";

function EditSupplierPackage({ supplierId, packageId }: { supplierId: string; packageId: string; }) {
    const [startPris, setStartPris] = useState<number | null>(null);
    const [dagsPris, setDagsPris] = useState<number | null>(null);
    const [helgeTillegg, setHelgeTillegg] = useState<number | null>(null);
    const [helligdagsPris, setHelligdagsPris] = useState<number | null>(null);
    const [newDate, setNewDate] = useState<Date | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [helligdager, setHelligdager] = useState<string[]>([]);

    useEffect(() => {
        async function fetchData() {
            const supplierRef = doc(db, "suppliers", supplierId);
            const supplierData = await getDoc(supplierRef);
    
            if (supplierData.exists()) {
                const currentPackage = (supplierData.data().packages || []).find((pkg: any) => pkg.id === packageId);
                if (currentPackage) {
                    setStartPris(currentPackage.startPris);
                    setDagsPris(currentPackage.dagsPris);
                    setHelgeTillegg(currentPackage.helgeTillegg);
                    setHelligdagsPris(currentPackage.helligdagsPris);
                    setHelligdager(currentPackage.helligdager || []);
                }
            }
            setIsLoading(false);
        }
        
        fetchData();
    }, [supplierId, packageId]);

    const handleRemoveDate = async (date: string) => {
        const updatedDates = helligdager.filter(helligdag => helligdag !== date);
        setHelligdager(updatedDates);
        
        const supplierRef = doc(db, "suppliers", supplierId);
        const supplierData = await getDoc(supplierRef);
    
        if (supplierData.exists()) {
            const packages = supplierData.data().packages || [];
            const pkgIndex = packages.findIndex((pkg: any) => pkg.id === packageId);
    
            if (pkgIndex !== -1) {
                // Copy the package, update its helligdager array
                const updatedPkg = { ...packages[pkgIndex] };
                updatedPkg.helligdager = updatedDates;
    
                // Replace the old package with the updated one
                packages[pkgIndex] = updatedPkg;
    
                // Update the packages array in the database
                await updateDoc(supplierRef, { packages });
            }
        }
    };

    const handleNewDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(e.target.value);
    
        // Oppdater databasen med den nye datoen
        const supplierRef = doc(db, "suppliers", supplierId);
        const supplierData = await getDoc(supplierRef);
        if (supplierData.exists()) {
            const packages = supplierData.data().packages || [];
            const pkgIndex = packages.findIndex((pkg: any) => pkg.id === packageId);
            if (pkgIndex !== -1) {
                // Copy the package and update its helligdager array
                const updatedPkg = { ...packages[pkgIndex] };
                if (!updatedPkg.helligdager) {
                    updatedPkg.helligdager = [];
                }
                updatedPkg.helligdager.push(selectedDate.toISOString());
    
                // Replace the old package with the updated one
                packages[pkgIndex] = updatedPkg;
    
                // Update the packages array in the database
                await updateDoc(supplierRef, { packages });
    
                // Update the local state for the UI to reflect the new date immediately
                setHelligdager(prevDates => [...prevDates, selectedDate.toISOString()]);
    
                // Reset the date picker
                setNewDate(null);
            }
        }
    };

    const handleSubmit = async () => {
        const supplierRef = doc(db, "suppliers", supplierId);
        const supplierData = await getDoc(supplierRef);
    
        if (supplierData.exists()) {
            const packages = supplierData.data().packages || [];
            const pkgIndex = packages.findIndex((pkg: any) => pkg.id === packageId);
    
            if (pkgIndex !== -1) {
                // Copy the package, update its values
                const updatedPkg = { ...packages[pkgIndex] };
                updatedPkg.startPris = startPris;
                updatedPkg.dagsPris = dagsPris;
                if (helgeTillegg !== null && helgeTillegg !== undefined) {
                    updatedPkg.helgeTillegg = helgeTillegg;
                }
                if (helligdagsPris !== null && helligdagsPris !== undefined) {
                    updatedPkg.helligdagsPris = helligdagsPris;
                }
                if (newDate) {
                    updatedPkg.helligdager = updatedPkg.helligdager || [];
                    updatedPkg.helligdager.push(newDate.toISOString());
                }
    
                // Replace the old package with the updated one
                packages[pkgIndex] = updatedPkg;
    
                // Update the packages array in the database
                await updateDoc(supplierRef, { packages });
            }
        }
    };
  
    if (isLoading) return <p>Loading...</p>;

    return (
        <span className='p-2'>
           <button 
                onClick={() => setIsModalOpen(true)}
                className="bg-green-400 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none"
            >
                Endre pris
           </button>
    
           {isModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg p-8 shadow-lg w-4/5 md:w-1/2 lg:w-1/3">
                        <h2 className="text-2xl mb-4 font-semibold">Rediger prisreglene</h2>
                        Startpris: <input type="number" value={startPris || ''} onChange={(e) => setStartPris(Number(e.target.value))} placeholder="Start Pris" className="w-full p-2 border rounded mb-3" />
                        Dagspris: <input type="number" value={dagsPris || ''} onChange={(e) => setDagsPris(Number(e.target.value))} placeholder="Dags Pris" className="w-full p-2 border rounded mb-3" />
                        Helgetillegg: <input type="number" value={helgeTillegg || ''} onChange={(e) => setHelgeTillegg(Number(e.target.value))} placeholder="0" className="w-full p-2 border rounded mb-3" />
                        Helligdagspris: <input type="number" value={helligdagsPris || ''} onChange={(e) => setHelligdagsPris(Number(e.target.value))} placeholder="0" className="w-full p-2 border rounded mb-3" />
                        Helligdager: {helligdager.map(date => (
                <div key={date} className="flex items-center mb-2">
                    <span className="mr-4">{new Date(date).toLocaleDateString()}</span>
                    <button onClick={() => handleRemoveDate(date)} className="text-red-500 hover:text-red-600">X</button>
                </div>
            ))}
            <input type="date" value={newDate ? newDate.toISOString().split('T')[0] : ''} onChange={handleNewDateChange} placeholder="Add New Date" className="w-full p-2 border rounded mb-3" />
                        <button onClick={handleSubmit} className="bg-green-400 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none mr-2">Lagre</button>
                        <button onClick={() => setIsModalOpen(false)} className="bg-red-400 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none">Lukk</button>
                    </div>
                </div>
           )}
        </span>
    );
}

export default EditSupplierPackage;