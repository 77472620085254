import { Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// @ts-ignore
import logo_uten_tekst from "../img/LogoUtenTekst.png";

function NavBarAdmin() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="px-2 w-100 font-josefin gap-3 px-4">
      <Navbar.Brand href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631"} className="italic"><Row>
          <Col >
            <img
              alt="logo_mobil"
              className="h-16 -mt-3 -mb-3 -ml-5 w-20"
              src={logo_uten_tekst}
            />
            </Col>
            <Col className="g-0 -ml-8 pt-2">
              <div className="font-bold text-md ">PartyLyd Admin</div>
            </Col>
            
          </Row></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto justify-content-end flex-grow-1 pe-4 gap-3 px-3">
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631"}>Alle bestillinger</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/lagerstyring"}>Lagerstyring</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/besokslogg"}>Besøkslogg</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/rabattkoder"}>Rabattkoder</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/skader"}>Skader</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/foresporsler"}>Forespørsler</Nav.Link>
          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/vedlikehold"}>Vedlikehold</Nav.Link>

          <Nav.Link href={"/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/leverandor"}>Leverandør</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBarAdmin;
