// @ts-nocheck
import { DocumentData, collection, getDocs, query } from "firebase/firestore";
import {
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Row,
  Form,
  InputGroup,
  Col,
  Button,
  Container,
  FormControl,
} from "react-bootstrap";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase1.ts";


import MyOrders from "../components/MyOrders.tsx";
import { db } from "../firebase1.ts";
import ModalComp from "../components/ModalComp.tsx";

const FindMyOrder = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [verifyCode, setVerifyCode] = useState(0);
  const [verifyInputCode, setVerifyInputCode] = useState(0);
  const [validate, setValidate] = useState("notChecked");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const functions = getFunctions(app, 'europe-west1');
  const verifyPhoneNumber = httpsCallable(functions, "verifyNumber");

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function verifyNumber(number: string) {
    let phoneNumber = number;
    let data = {
      tlf: phoneNumber,
    };
    verifyPhoneNumber(data).then((response) => {
      setVerifyCode(response.data);
    });
  }

  const fetchData = async () => {
    let list = [];

    // Query for sales collection
    const salesQuery = query(collection(db, "sales"));
    const supplierSalesQuery = query(collection(db, "supplierSales"));

    try {
        // Fetch documents from the sales collection
        const salesDocs = await getDocs(salesQuery);
        salesDocs.forEach((doc) => {
            if ((doc.data().tlf === "+47" + phoneNumber || doc.data().tlf === phoneNumber) && 
                doc.data().paymentStatus === "paid") {
                list.push({ id: doc.id, ...doc.data() });
            }
        });

        // Fetch documents from the supplierSales collection
        const supplierSalesDocs = await getDocs(supplierSalesQuery);
        supplierSalesDocs.forEach((doc) => {
            if ((doc.data().tlf === "+47" + phoneNumber || doc.data().tlf === phoneNumber)) {
                list.push({ id: doc.id, ...doc.data() });
            }
        });

        // Update the data state with the combined list from both collections
        setData([...list]);

        console.log("DATA: ", list); // Logs the combined list of data
    } catch (e) {
        console.log(e);
    }
  };
  /* const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "sales"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        if (
          (doc.data().tlf === "+47"+phoneNumber || doc.data().tlf === phoneNumber) &&
          doc.data().paymentStatus === "paid"
        ) {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      setData([...list]);

      console.log("DATA; ", data)
    } catch (e) {
      console.log(e);
    }
  }; */

  const checkVerifyCode = () => {
    if (verifyCode === verifyInputCode) {
      setValidate("true");
      fetchData();
      setShow(false);
    } else {
      setValidate("false");
      setShow(false);
    }
  };

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setPhoneNumber(message);
    setShow(true);
  };

  useEffect(() => {
    if (phoneNumber !== "") {
      verifyNumber(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (show === true) {
      setData([]);
      setValidate("notChecked");
    }
  }, [show]);

  return (
    <>
      {/** Modal for å vertifisere*/}
      <ModalComp
        show={show}
        closeModal={() => setShow(false)}
        title="Verifisering av mobilnummer"
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16 mt-3"
            onClick={handleClose}
          >
            Lukk
          </Button>
        }
        body={
          <>
            <InputGroup>
              <Container className="mt-6 mb-6">
                <Row>
                  <div className="text-md tracking-wider font-weight-400">
                    Du vil nå motta en verifiseringskode på SMS, skriv inn koden
                    her: (kan ta opptil 1 minutt)
                  </div>
                  <Col
                    lg={{ span: 8, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <FormControl
                      placeholder="Skriv inn verifiseringskode mottatt på SMS"
                      aria-describedby="verify-code"
                      onChange={(e) =>
                        setVerifyInputCode(Number(e.target.value))
                      }
                    />
                  </Col>
                  <Col
                    lg={{ span: 3, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <Button
                      className="tracking-wide w-100"
                      style={{
                        backgroundColor: "#e57e22",
                        borderColor: "#e57e22",
                        fontWeight: 400,
                      }}
                      onClick={checkVerifyCode}
                    >
                      Sjekk kode
                    </Button>
                  </Col>
                </Row>
              </Container>
            </InputGroup>
          </>
        }
      />
      <Row className="bg-white mt-6 font-josefin">
        <Form>
          <Container className="mt-6 mb-6">
            <Row>
              <InputGroup>
                <Col lg={{ span: 2, order: 0, offset: 2 }} xs={{ span: 12 }}>
                  <div className="mt-1 font-bold text-lg tracking-wide lg:mb-0 mb-6">
                    HAR DU ALT BESTILT?
                  </div>
                </Col>
                <Col
                  lg={{ span: 4, order: 1 }}
                  xs={{ span: 12 }}
                  className="lg:mb-0 mb-6"
                >
                  <Form.Control
                    placeholder="Telefonnummer brukt til bestilling"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Col>
                <Col lg={{ span: 4, order: 2 }} xs={{ span: 12 }}>
                  <Button
                    className="tracking-wide lg:ml-2"
                    onClick={handleClick}
                    style={{
                      backgroundColor: "#e57e22",
                      borderColor: "#e57e22",
                      fontWeight: 400,
                    }}
                  >
                    Finn bestilling
                  </Button>
                </Col>
              </InputGroup>
            </Row>
          </Container>
        </Form>
      </Row>
      {validate === "false" && <p>Feil vertifiseringskode</p>}
      {data.length !== 0 ? (
        <div className="place-items-center font-josefin mb-10">
          {data.map((item) => (
            <MyOrders
              key={item.id}
              startDate={capitalize(
                new Date(item.tid.startDate.seconds * 1000).toLocaleString(
                  `no-NO`,
                  options
                )
              )}
              endDate={capitalize(
                new Date(item.tid.endDate.seconds * 1000).toLocaleString(
                  `no-NO`,
                  options
                )
              )}
              packageType={item.packageType}
              totalPrice={item.totalPris}
              orderId={item.id}
              phoneNumber={phoneNumber}
              storage={item.lager}
              supplierId={item?.supplierId}
            />
          ))}
        </div>
      ) : null}
      {validate === "false" ? (
        <div>
          Valideringskoden du skrev inn matchet ikke med den som ble sendt på
          melding. Vennligst prøv igjen.
        </div>
      ) : null}

      {validate === "true" && data.length === 0 ? (
        <>
        <Container>
          <Row>
            <Col lg={{span: 6, offset: 3}}>
          <div className="px-2 pt-8">
            Vi finner dessverre ingen bestillinger lagt inn med nummeret oppgitt
            her: <strong>{phoneNumber}</strong>.
          </div>
          <br></br>
          <div className="px-2">
            Vennligst sjekk at du har skrevet inn riktig nummer, det som ble oppgitt under bestilling, eller ta
            kontakt med oss.
          </div>
          </Col>
          </Row>
          </Container>
        </>
      ) : null}
    </>
  );
};

export default FindMyOrder;
