// @ts-ignore
import logo1 from "../img/LogoPL.png";
import { Col, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const NotFound = () => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate("/");
    };
  return (
    <div className="min-h-screen font-josefin">
    <Container>
      <Col lg={{ span: 6, offset: 4 }} xs={{ span: 12, offset: 1}}>
        <img alt="logo" src={logo1} className="h-80 pb-0 -mb-48 lg:pl-16" />
      </Col>

      <Col lg={{ span: 6, offset: 5 }} xs={{ span: 12, offset: 3}}>
        <div className="text-[#f39c12] g:pl-20 pt-36 font-bold text-5xl sm:text-6xl tracking-wide pb-8 lg:pl-8 pl-6">
          Oops!
        </div>
      </Col>

      <Col lg={{ span: 8, offset: 4 }} xs={{ span: 12 }}>
        <div className="text-black font-sm text-xl lg:pl-8">
        Det ser ikke ut til at vi finner siden du leter etter.
        </div>
      </Col>
      <Col lg={{ span: 3, offset: 5 }} xs={{ span: 12 }}>
        <Button
          className="tracking-wide w-100 lg:-ml-10 mt-16 h-14"
          style={{
            backgroundColor: "black",
            borderColor: "black",
            fontWeight: 600,
          }}
          onClick={handleClick}
        >
          Tilbake til forsiden
        </Button>
      </Col>
    </Container>
  </div>
  );
};

export default NotFound;
