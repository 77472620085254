// @ts-ignore
import { Col, Row, Card, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const categories = [
  {
    img_src:
      "https://www.bruksanvisningpdf.no/thumbs/products/l/1546738-soundboks-3.jpg",
    title: "Soundboks",
    id: "soundboks",
    bg: "#E3826F",
    description1: "God lyd og bass for en mindre og praktisk fest. Enkel booking over hele landet.",
    description2: "Oppdag Soundboks 3 – et konsept som definerer festlyd. Bærbar, kraftig, og designet for lettvint gjennomføring. Med enkel booking over hele Norge, kan du nå skape den perfekte stemningen for ditt arrangement. Ikke la musikken være en ettertanke; la den være høydepunktet."
  },
  {
    img_src:
      "https://www.sirkusagio.no/uploads/MdC2suTL/242x0_242x0/Slushmaskin_leie_trekammer.jpg",
    title: "Slushmaskin",
    id: "slushmaskin",
    description1: "Forfriskende slush for alle anledninger. Perfekt for sommerfester og events.",
    description2: "Ta festen eller arrangementet ditt til neste nivå med vår premium slushmaskin utleie i Norge. Perfekt for sommerfester, barnebursdager eller bedriftseventer, gir vår slushmaskin deilige, kjølige forfriskninger som gjestene dine vil elske. Enkel booking og konkurransedyktige priser. Reserver din slushmaskin nå og server smaken av kjølig glede!"
  },
  {
    img_src:
      "https://www.sirkusagio.no/uploads/snDni9EQ/737x0_360x0/vognogsuper.png",
    title: "Popcornmaskin",
    id: "popcorn",
    description1: "Server ferskt, velsmakende popcorn på minutter. En autentisk kinoopplevelse.",
    description2: "Gjør filmkvelden, bursdagen eller eventet ditt ekstra spesielt med vår popcornmaskin utleie i Norge. Med bare et trykk på en knapp kan du servere nydelig, ferskt popcorn som smelter i munnen. Våre maskiner er brukervennlige, rene og klare til bruk. Uansett anledning, gir popcornmaskinen en ekstra touch av moro og nostalgi. Book din popcornmaskin i dag og nyt den autentiske kinoopplevelsen!"
  },
  {
    img_src:
      "https://showup.no/wp-content/uploads/2015/10/344227017_909292860335349_2579018455381374044_n-600x469.jpg",
    title: "Hoppeslott",
    id: "hoppeslott",
    description1: "Timesvis med moro for barn og voksne. Sikker underholdning for alle anledninger.",
    description2:"Ser du etter den ultimate underholdningen for barna? Vår hoppeslott utleie i Norge er svaret! Med fargerike design og sikre materialer, gir våre hoppeslott timesvis med hopp og glede. Perfekt for bursdager, skolearrangementer eller bare en dag i hagen. Med enkel online booking og levering over hele landet, har det aldri vært enklere å gi barna en dag de aldri vil glemme. Lei ditt hoppeslott nå og se smilene lyse opp!"
  },
];
localStorage.setItem('categories', JSON.stringify(categories));

const Supplier = () => {
  const navigate = useNavigate();

  const navigateToPackage = (site: string) => {
    navigate(site);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Row className="justify-content-md-center mt-10 pb-10">
        <Col md={10} className="">
          <div className="font-normal text-lg tracking-wider font-josefin ml-2 text-center">
            {" "}
            Bestill utstyr over hele Norge 
          </div>
          <div className="font-normal text-4xl tracking-wider pb-8 ml-2 text-center">
            {" "}
            Levert av våre leverandører
          </div>

          <Container>
            <div>
              <div>
                <Row className="justify-content-md-center">
                  {categories.map((object) => (
                      <Col
                        md={{ span: 3, offset: 0 }}
                        xs={{ span: 12, offset: 0 }}
                      >
                        <a
                          href="#!"
                          data-mdb-ripple="true"
                          data-mdb-ripple-color="light"
                        >
                          <Card
                            className="rounded-[20px] m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray font-josefin bg"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigateToPackage(object.id)}
                          >
                            <Card.Img
                              variant="top"
                              className="mt-2"
                              src={object.img_src}
                              style={{
                                width: "100%",
                                height: "24vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                borderRadius: "20px 20px 20px 20px",
                              }}
                            />
                            <Card.Body className="text-center">
                              <Card.Title className="text-2xl font-bold ">
                                {"Leie " + object.title}
                              </Card.Title>
                              <Card.Text className="text-md font-normal mt-2">
                                {object.description1}
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </a>
                      </Col>
                    )
                  )}
                </Row>
                <div className="mt-5 mb-5 text-center">
              <p>
                Partylyd.no - Din Ultimate Partner for Festutstyr i Norge. Når det kommer til å skape den perfekte feststemningen, er valget av riktig utstyr avgjørende. Hos Partylyd.no forstår vi viktigheten av kvalitet og pålitelighet. Derfor tilbyr vi et bredt spekter av festutstyr tilgjengelig for utleie over hele Norge, levert av våre pålitelige leverandører. Velg Partylyd.no for ditt neste arrangement og opplev forskjellen med kvalitetsutstyr og førsteklasses service.
              </p>
            </div>
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Supplier;
