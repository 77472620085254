// @ts-nocheck
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";
import { db } from "../firebase1.ts";
import Loading from "../components/Loading.tsx";

type OurPackagesProps = {
  objects?: Array<{
    img_src: string;
    title: string;
    link: string;
    subtitle: string;
    text: string;
  }>;
};

const OurPackages: FC<OurPackagesProps> = ({ objects }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<DocumentData[]>([]);

  const navigateToPackage = (site: string) => {
    if (site === "Ordinær lydpakke") {
      navigate(`/pakke/ordinarlydpakke`);
    } else {
      let url = site.replace(/\s/g, "").toLowerCase();
      navigate(`/pakke/${url}`);
    }
    window.scrollTo(0, 0);
  };

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "packages"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData([...list]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  return (
    <>
      <div>
        <div className="mx-auto bg-white overflow-hidden max-w-6xl mb-8 px-2">
          <div className="md:flex md:shrink-0 font-josefin">
            <Row md={3} className="g-2">
              <Col md={{ span: 10, offset: 0 }}>
                <div className="pt-8 font-bold text-3xl tracking-wide pb-6 font-josefin">
                  {" "}
                  Utleie av lyd
                </div>
                <div>
                  <div>
                    Det er viktig med godt lydutstyr om bryllupet, hagefesten,
                    russefeiringen, bursdagen eller firmafesten skal bli en
                    suksess! Om du mangler utstyret du trenger, kan du enkelt
                    leie lydutstyr av høy kvalitet gjennom PartyLyd. Vi har
                    blant annet disse pakkene:
                  </div>
                  <ul className="pl-6 py-2">
                    <a href="/pakke/kraftiglydpakke">
                      <li className="hover:text-[#e57e22]">
                        &#x2022; Kraftig lydpakke
                      </li>
                    </a>
                    <a href="/pakke/kraftiglydpakke-ekstrabass">
                      <li className="mt-1 hover:text-[#e57e22]">
                        &#x2022; Kraftig lydpakke med ekstra bass
                      </li>
                    </a>
                    <a href="/pakke/talepakke">
                      <li className="hover:text-[#e57e22] mt-1">
                        &#x2022; Talepakke
                      </li>
                    </a>
                  </ul>

                  <div className="pb-2">
                    Kraftig lydpakke er vår bestselger, og er midt i blinken for
                    alt fra bursdager til bryllup. Ønsker du ekstra trøkk, kan
                    du også velge leie av anlegg med ekstra bass. Kanskje noe
                    for russefesten eller deg som skal arrangere tidenes
                    firmafest?
                  </div>
                  <div className="pb-6">
                    Vi har også lydutleie for formidling av tale. Talepakke
                    lydpakke er ideell for store folkemengder, og er både
                    kompakt og brukervennlig. Perfekt når du ønsker å tale eller
                    dele musikk på 1, 2, 3.
                  </div>
                </div>
              </Col>
              {!loading ? (
                <>
                  {data.map(
                    (object: {
                      bilde: string;
                      title: string;
                      link: string;
                      subtitle: string;
                      text: string;
                    }) => {
                      if (
                        object.id != "TestPakke" &&
                        object.festPakkeType === "lydpakke"
                      ) {
                        return (
                          <Col xs={{ span: 12, offset: 0 }}>
                            <a
                              href="#!"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <Card
                                className="rounded-xl p-2 m-1 shadow-md border-gray hover:drop-shadow-xl hover:text-black "
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateToPackage(object.id)}
                              >
                                <Card.Img
                                  variant="top"
                                  src={object.bilde}
                                  style={{
                                    width: "100%",
                                    height: "30vh",
                                    objectFit: "contain",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                                <Card.Body>
                                  <Card.Title className="text-3xl tracking-wide font-normal line-clamp-1">
                                    {object.id}
                                  </Card.Title>
                                  <Card.Subtitle className="font-bold pb-2 text-base">
                                    Pris fra {object.startPris+object.dagsPris},-
                                  </Card.Subtitle>
                                  <Card.Text className="line-clamp-5">
                                    {object.beskrivelse}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </a>
                          </Col>
                        );
                      }
                      return null;
                    }
                  )}
                </>
              ) : (
                <Col md={{ span: 8, offset: 5 }} className="mb-6 p-24 mr-20">
                  <div className="p-24">
                  {" "}
                  <Loading color={"#e57e22"} />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="bg-[#e57e22] pb-8">
          <div className="mx-auto overflow-hidden max-w-6xl px-2">
            <div className="md:flex md:shrink-0 font-josefin">
              <Row md={3} className="g-2">
                <Col md={{ span: 10, offset: 0 }}>
                  <div className="pt-8 font-bold text-3xl tracking-wide pb-6 text-white font-josefin">
                    {" "}
                    Utleie av lys
                  </div>
                  <div className="text-white">
                    <div>
                      Lys er for mange vel så viktig som lyd, og bidrar
                      utvilsomt til å sette stemningen! Vi har pakker med
                      lysutstyr på utleie, slik at du enkelt kan gjøre
                      arrangementet ditt uforglemmelig. Her er pakkene vi tilbyr
                      innen utleie av lys:
                    </div>
                    <ul className="pl-6 py-2">
                      <a href="/pakke/effektpakke">
                        <li className="hover:text-black">
                          &#x2022; Effektpakke (diskolys og laser)
                        </li>
                      </a>
                      <a href="/pakke/stemningspakke">
                        <li className="mt-1 hover:text-black">
                          &#x2022; Stemningspakke (lys opp lokalet og skap
                          stemning med ulike moduser og farger)
                        </li>
                      </a>
                    </ul>
                    <div className="pb-2">
                      Effektpakke er perfekt når du ønsker lyseffekter på
                      dansegulvet. De kraftige LED-pærene og laserlysene har
                      ulike moduser, og kan blant annet blinke i takt med
                      musikken. Du kan gjerne leie strobelys som tillegg, eller
                      kombinere med stemningslys.
                    </div>{" "}
                    <div className="pb-6">
                      Stemningspakken er skreddersydd for deg som vil leie
                      lysutstyr til for eksempel bryllup, årslag eller andre
                      arrangementer. Skap en unik og god atmosfære med mer enn
                      250 ulike farger og UV-lys.
                    </div>
                  </div>
                </Col>
                {!loading ? (
                  <>
                    {data.map(
                      (object: {
                        bilde: string;
                        title: string;
                        link: string;
                        subtitle: string;
                        text: string;
                      }) => {
                        if (
                          object.id != "TestPakke" &&
                          object.festPakkeType === "lyspakke"
                        ) {
                          return (
                            <Col xs={{ span: 12, offset: 0 }}>
                              <a
                                href="#!"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                              >
                                <Card
                                  className="rounded-xl p-2 m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigateToPackage(object.id)}
                                >
                                  <Card.Img
                                    variant="top"
                                    src={object.bilde}
                                    style={{
                                      width: "100%",
                                      height: "30vh",
                                      objectFit: "contain",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                  <Card.Body>
                                    <Card.Title className="text-3xl tracking-wide font-normal">
                                      {object.id}
                                    </Card.Title>
                                    <Card.Subtitle className="font-bold pb-2 text-base">
                                      Pris fra {object.startPris+object.dagsPris},-
                                    </Card.Subtitle>
                                    <Card.Text className="line-clamp-5">
                                      {object.beskrivelse}
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </a>
                            </Col>
                          );
                        }
                        return null;
                      }
                    )}
                  </>
                ) : (
                  <Col md={{ span: 8, offset: 5 }} className="mb-6 p-24 mr-20">
                  <div className="p-24">
                  {" "}
                  <Loading color={"#FFF"} />
                  </div>
                </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
        <div className="mx-auto overflow-hidden max-w-6xl pb-12 px-2">
          <div className="md:flex md:shrink-0 font-josefin">
            <Row md={3} className="g-2">
              <Col md={{ span: 10, offset: 0 }}>
                <div className="pt-8 font-bold text-3xl tracking-wide pb-6 font-josefin">
                  {" "}
                  Utleie av lyd og lys
                </div>
                <div>
                  <div>
                    Ingen fest uten musikk og lyseffekter! Vi tilbyr derfor
                    samlet utleie av lyd og lys, slik at du får alt utstyret du
                    trenger på 1, 2, 3. Ved å leie anlegg og lysutstyr
                    kombinert, kan du sørge for at både lydbildet og det
                    visuelle blir så gjennomført som mulig. Her er våre
                    pakkeløsninger innen lys og lyd:
                  </div>
                  <ul className="pl-6 py-2">
                    <a href="/pakke/mediumpartypakke">
                      <li className="hover:text-[#e57e22]">
                        &#x2022; Medium Partypakke (opptil 100 personer)
                      </li>
                    </a>
                    <a href="/pakke/storpartypakke">
                      <li className="mt-1 hover:text-[#e57e22]">
                        &#x2022; Stor Partypakke (opptil 150 personer)
                      </li>
                    </a>
                  </ul>

                  <div className="pb-6">
                    Med en partypakke kan du se frem til optimal lyd og diskolys
                    av høy kvalitet. Husk at utstyret kan kombineres med
                    ekstrautstyr som mikrofoner, røykmaskiner, strobelys og
                    lignende. Med lys, lyd og fornøyde gjester, ligger alt til
                    rette for en uforglemmelig fest!
                  </div>
                </div>
              </Col>
              {!loading ? (
                <>
                  {data.map(
                    (object: {
                      bilde: string;
                      title: string;
                      link: string;
                      subtitle: string;
                      text: string;
                    }) => {
                      if (
                        object.id !== "TestPakke" &&
                        object.festPakkeType === "lydoglyspakke"
                      ) {
                        return (
                          <Col xs={{ span: 12, offset: 0 }}>
                            <a
                              href="#!"
                              data-mdb-ripple="true"
                              data-mdb-ripple-color="light"
                            >
                              <Card
                                className="rounded-xl p-2 m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateToPackage(object.id)}
                              >
                                <Card.Img
                                  variant="top"
                                  src={object.bilde}
                                  style={{
                                    width: "100%",
                                    height: "30vh",
                                    objectFit: "contain",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                                <Card.Body>
                                  <Card.Title className="text-3xl tracking-wide font-normal">
                                    {object.id}
                                  </Card.Title>
                                  <Card.Subtitle className="font-bold pb-2 text-base">
                                    Pris fra {object.startPris+object.dagsPris},-
                                  </Card.Subtitle>
                                  <Card.Text className="line-clamp-5">
                                    {object.beskrivelse}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </a>
                          </Col>
                        );
                      }
                      return null;
                    }
                  )}
                </>
              ) : (
                <Col md={{ span: 8, offset: 5 }} className="mb-6 p-24 mr-20">
                <div className="p-24">
                {" "}
                <Loading color={"#e57e22"} />
                </div>
              </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="bg-[#e57e22] pb-8 ">
          <div className="mx-auto overflow-hidden max-w-6xl pb-12 px-2">
            <div className="md:flex md:shrink-0 font-josefin">
              <Row md={3} className="g-2">
                <Col md={{ span: 10, offset: 0 }}>
                  <div className="pt-8 font-bold text-3xl tracking-wide pb-6 font-josefin text-white">
                    {" "}
                    Utleie av bord, stoler og partytelt
                  </div>
                  <div className="text-white">
                    <div>
                      For at festen, konferansen, bryllupet, eller sommerfesten
                      skal bli en suksess, er det essensielt med riktig og nok
                      sitteplasser og dekning for regn og sterk sol. Vi har egne
                      pakkeløsninger for utleie av bord, stoler og partytelt:
                    </div>
                    <ul className="pl-6 py-2">
                      <a href="/pakke/bord">
                        <li className="hover:text-black">
                          &#x2022; Bord (klappbord for 6-8 personer)
                        </li>
                      </a>
                      <a href="/pakke/stoler">
                        <li className="mt-1 hover:text-black">
                          &#x2022; Stoler (klappstol med polstring)
                        </li>
                      </a>
                      <a href="/pakke/partytelt">
                        <li className="mt-1 hover:text-black">
                          &#x2022; Partytelt (6x3m)
                        </li>
                      </a>
                    </ul>

                    <div className="pb-6">
                      Våre bord og stoler er robuste, og partyteltene er både
                      vanntette og pop-up basert (monteres på få minutter). Du
                      får mulighet til å leie flere bord og stoler ved alle
                      pakkeløsningene. Med våre løsninger for utleie av bord,
                      stoler og partytelt, er du et steg nærmere en vellykket og
                      minneverdig begivenhet!
                    </div>
                  </div>
                </Col>
                {!loading ? (
                  <>
                    {data.map(
                      (object: {
                        bilde: string;
                        title: string;
                        link: string;
                        subtitle: string;
                        text: string;
                      }) => {
                        if (
                          object.id !== "TestPakke" &&
                          object.festPakkeType === "bordStolerTelt"
                        ) {
                          return (
                            <Col xs={{ span: 12, offset: 0 }}>
                              <a
                                href="#!"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                              >
                                <Card
                                  className="rounded-xl p-2 m-1 hover:drop-shadow-xl hover:text-black shadow-md border-gray"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigateToPackage(object.id)}
                                >
                                  <Card.Img
                                    variant="top"
                                    src={object.bilde}
                                    style={{
                                      width: "100%",
                                      height: "30vh",
                                      objectFit: "contain",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                  <Card.Body>
                                    <Card.Title className="text-3xl tracking-wide font-normal">
                                      {object.id}
                                    </Card.Title>
                                    <Card.Subtitle className="font-bold pb-2 text-base">
                                      Pris fra {object.startPris+object.dagsPris},-
                                    </Card.Subtitle>
                                    <Card.Text className="line-clamp-1">
                                      {object.beskrivelse}
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </a>
                            </Col>
                          );
                        }
                        return null;
                      }
                    )}
                  </>
                ) : (
                  <Col md={{ span: 8, offset: 5 }} className="mb-6 p-24 mr-20">
                  <div className="p-24">
                  {" "}
                  <Loading color={"#FFF"} />
                  </div>
                </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPackages;