
// @ts-nocheck
import {
  collection,
  DocumentData,
  getDocs,
  getDoc,
  query,
  arrayRemove,
  doc,
  updateDoc,
  arrayUnion, 
} from "firebase/firestore";
import { SetStateAction, useEffect, useState } from "react";
import { Card, Col, Row, Button, Form, Container } from "react-bootstrap";
import ModalComp from "../components/ModalComp.tsx";
import { db } from "../firebase1.ts";
import { useNavigate } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase1.ts";
import SupplierCard from "../components/SupplierCard.tsx";
import { useLocation } from "react-router-dom";
import { GrClose } from "react-icons/gr";

import Checklist from "../components/Checklist.tsx";
import { report } from "process";


const MaintanceChecklist = () => {
  const [tasks, setTasks] = useState([]);
  const [location, setLocation] = useState("");
  const locations = ["trondheim", "stavanger", "lorenskog", "vinterbro", "asker", "bergen", "kristiansand"];
  const [maintenanceTasks, setMaintenanceTasks] = useState([]);
  const [otherTasks, setOtherTasks] = useState([]);

  const [showReport, setShowReport] = useState(false);
  const handleShowReportCancel = () => setShowReport(false);
  const [reportDescription, setReportDescription] = useState("");

  const [showFailed, setShowFailed] = useState(false);
  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  const [sentReport, setSentReport] = useState(false);

  async function fetchData(taskType: string) {
    const list = [];
    const q = query(collection(db, "storage-stamp"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        if (doc.id === location) {
          doc.data()[taskType].forEach(task => list.push(task));
        }
      });
    } catch (e) {
      console.log(e);
      // Vurder å vise en feilmelding til brukeren her
    }
    if (taskType === "maintenance") {
      setMaintenanceTasks(list);
    } else {
      setOtherTasks(list);
    }
  }


  useEffect(() => {
    fetchData("maintenance");
    fetchData("tasks");
  }, [location]);

  const markMaintenanceTaskAsDone = async (taskToUpdate) => {
    const docRef = doc(db, "storage-stamp", location);
    try {
      // Hent nåværende oppgaver fra databasen
      const currentTasks = (await getDoc(docRef)).data().maintenance;
  
      // Oppdater den spesifikke oppgaven
      const updatedTasks = currentTasks.map(task => 
        task.id === taskToUpdate.id ? {...task, done: !task.done} : task
      );
  
      // Lagre de oppdaterte oppgavene tilbake til databasen
      await updateDoc(docRef, { maintenance: updatedTasks });
      
      // Set local state to updated tasks
      setMaintenanceTasks(updatedTasks);
  
    } catch (error) {
      console.error("Error updating maintenance task:", error);
      // Vurder å vise en feilmelding til brukeren her
    }
  };

const markOtherTaskAsDone = async (taskToUpdate) => {
  console.log("KJØRER")
  console.log(taskToUpdate)
    const docRef = doc(db, "storage-stamp", location);
    try {
        // Hent nåværende oppgaver fra databasen
        const currentTasks = (await getDoc(docRef)).data().tasks;

        // Oppdater den spesifikke oppgaven
        const updatedTasks = currentTasks.map(task => 
            task.task === taskToUpdate.task ? {...task, done: !task.done} : task
        );

      // Set local state to updated tasks
      setOtherTasks(updatedTasks);

        // Lagre de oppdaterte oppgavene tilbake til databasen
        await updateDoc(docRef, { tasks: updatedTasks });

    } catch (error) {
        console.error("Error updating other task:", error);
        // Vurder å vise en feilmelding til brukeren her
    }
};

async function addReportDB(location, reportDescription) {
  const currentDate = new Date();
  const dateString = currentDate.toISOString();

  const report = {
    description: reportDescription,
    date: dateString
  };

  const gearUpdate = doc(db, "storage-stamp", location);

  updateDoc(gearUpdate, {
    reports: arrayUnion(report)
  })
  .then(() => {
    setShowReport(false);
    setReportDescription("");
    setSentReport(true);
  })
  .catch((e) => {
    console.error("Error adding report:", e);
    handleShowFailed();
  });
}

const handleChangeReport = (e: any) => {
  setReportDescription(e.target.value);
};

  return (
    <Container className="pb-4">
      <ModalComp
        show={showReport}
        closeModal={() => setShowReport(false)}
        title={`Rapportering av vedlikehold på  ${location}`}
        hide={handleShowReportCancel}
        body={
          <>
            Legg til en beskrivelse av periodevis vedlikehold.
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.Report"
                onChange={handleChangeReport}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${reportDescription}`}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    addReportDB(location, reportDescription);
                  }}
                >
                  Send inn rapport
                </Button>
                <Button
                  variant="secondary"
                  className="text-[#24292e] border-[#24292e] text-left ml-2"
                  onClick={handleShowReportCancel}
                >
                  Lukk
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      <ModalComp
        show={showFailed}
        closeModal={() => setShowFailed(false)}
        hide={handleCloseFailed}
        body={
          <>
            <p>
              Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
              senere, eller ta kontakt med teknisk ansvarlig.
            </p>
          </>
        }
      />

      <div className="mb-4 p-4">
  <label htmlFor="form.Location" className="block w-1/4 font-medium text-gray-700 text-xl">Lager</label>
  <select
    id="form.Location"
    value={location}
    onChange={(e) => {
      setLocation(e.target.value);
      setSentReport(false);
    }}
    className="mt-1 block w-1/3 py-2 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
  >
    <option value="">Velg lager</option>
    {locations.map((location, index) => (
      <option value={location} key={index}>
        {location.charAt(0).toUpperCase() + location.slice(1)}
      </option>
    ))}
  </select>
  {location && location !== "" && (
    <Button
  className="pl-4 mt-4 pr-4 text-blue-500"
  variant="secondary"
  onClick={(e) => {
      e.stopPropagation();
      if (!sentReport) {
        setShowReport(true);
      }
  }}
  disabled={sentReport} 
>
  {sentReport ? "Rapport sendt" : "Send rapport"}
</Button>
)}
</div>
<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
<div>
    <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0">
      Faste oppgaver
    </div>
    <Checklist tasks={otherTasks} markTaskAsDone={markOtherTaskAsDone} admin={false}></Checklist>
  </div>

  <div>
    <div className="font-medium text-3xl tracking-wider pb-2 pt-10 lg:pl-28 lg:ml-0">
      Nye oppgaver
    </div>
    <Checklist tasks={maintenanceTasks} markTaskAsDone={markMaintenanceTaskAsDone} admin={false}></Checklist>
  </div>
  {location && location !== "" && (
  <Button
  className="pl-4 mt-4 pr-4 text-blue-500"
  variant="secondary"
  onClick={(e) => {
      e.stopPropagation(); // Stopper klikket fra å boble opp til foreldrekomponenter
      if (!sentReport) {
        setShowReport(true);
      }
  }}
  disabled={sentReport} // Deaktiverer knappen hvis 'sentReport' er true
>
  {sentReport ? "Rapport sendt" : "Send rapport"}
</Button>
)}


</div>
  </Container>
  );
};

export default MaintanceChecklist;