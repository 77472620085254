import React, { useState, useEffect, FC } from 'react';
import { Modal, Button, Card, Form } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa";
import { GrStatusUnknown, GrStatusGood, GrStatusDisabled } from "react-icons/gr";
import { FaRegComment, FaRegCommentDots, FaTools } from "react-icons/fa";
import "../style/carousel.css";
import {
  collection,
  DocumentData,
  getDoc,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase1.ts";
import {
  checkAvailability,
  gearManagement
} from "../components/Backend.tsx";


interface AdminCardProps {
  startDate?: any;
  endDate?: any;
  packageType?: string;
  orderId?: any;
  phoneNUmber?: number;
  totalPrice?: number;
  deliveryMethod?: string;
  storage?: string;
  visibility?: string;
  visibilityComment?: string;
  visibilityNoComment?: string;
  orderIdLink?: string;
  status?: string;
  onClick?: () => void;
  onClickRegnskap?: () => void;
  onClickComment?: () => void;
  utstyr: any;
}

const AdminCard: FC<AdminCardProps> = ({
  startDate,
  endDate,
  visibility,
  packageType = "",
  orderId,
  orderIdLink,
  phoneNUmber,
  totalPrice,
  storage = "",
  status = "Ikke hentet",
  visibilityComment,
  visibilityNoComment,
  onClick,
  onClickRegnskap,
  onClickComment,
  utstyr
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const [localEquipment, setLocalEquipment] = useState(utstyr);

  const fetchEquipmentData = async () => {
    try {
      const docRef = doc(db, 'sales', orderIdLink);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        setLocalEquipment(docSnapshot.data().utstyr);
        console.log(docSnapshot.data().utstyr);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }

  const handleEquipmentChange = async (key: string, action: string) => {
    console.log("Handling equipment change for:", key, action);
    
    let updatedValue = localEquipment[key];
if (action === "add") {
    updatedValue += 1;
} else if (action === "subtract") {
    if (updatedValue <= 0) {
        console.error("Kan ikke redusere utstyret under 0.");
        alert("Kan ikke redusere utstyret under 0."); // Dette vil vise en popup til brukeren
        return; // Dette vil avbryte resten av funksjonen
    }
    updatedValue -= 1;
}

    // Opprett en ny utstyrsliste hvor kun utstyret som legges til har en verdi, og alt annet har 0
    const equipmentChange = Object.keys(localEquipment).reduce((acc, equipmentKey) => {
      acc[equipmentKey] = equipmentKey === key ? (action === "add" ? 1 : -1) : 0;
      return acc;
  }, {});

    // Sjekker lagerstyring. Gir true / false på ca.available
    //HER ER DET FEIL INPUT-VERDIER
    const startDateParsed = new Date(Date.parse(startDate));
    const endDateParsed = new Date(Date.parse(endDate));
    const storageParsed = storage.toLowerCase();
    let ca = await checkAvailability(
      equipmentChange,
      storageParsed,
      startDateParsed,
      endDateParsed
    );

   // Hvis det ikke er tilgjengelig, avslutt funksjonen her
if (!ca.available) {
  console.error("Utstyret er ikke tilgjengelig for de valgte datoene.");
  alert("Utstyret er ikke tilgjengelig for de valgte datoene."); // Dette vil vise en popup til brukeren
  return; // Dette vil avbryte resten av funksjonen
}

    const updatedEquipment = { ...localEquipment, [key]: updatedValue };
    console.log("Updated equipment:", updatedEquipment);

    // Oppdaterer localEquipment state
    setLocalEquipment(updatedEquipment);

    //Oppdaterer lagerstyringen
    const startDateInSeconds = Math.floor(startDateParsed.getTime() / 1000);
    const endDateInSeconds = Math.floor(endDateParsed.getTime() / 1000);
    await gearManagement(equipmentChange, storageParsed, startDateInSeconds, endDateInSeconds, false);

    // Lagrer til Firebase
    console.log("Saving to Firebase with data:", updatedEquipment);
    try {
        const docRef = doc(db, 'sales', orderIdLink);
        await updateDoc(docRef, { utstyr: updatedEquipment });
    } catch (error) {
        console.error("Error saving data: ", error);
    }
};

  const [showEquipmentModal, setShowEquipmentModal] = useState(false);

return (
  <div className="tracking-wide font-light ">
    <Modal show={showEquipmentModal} onHide={() => setShowEquipmentModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Rediger utstyr</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
  {localEquipment && Object.keys(localEquipment).sort().map(key => (
    <Form.Group key={key} className="d-flex align-items-center">
      <Form.Label className="mr-2">{key}</Form.Label>
      <Form.Control
        type="number"
        className="mr-2"
        value={localEquipment[key]}
        readOnly // Gjør dette feltet skrivebeskyttet siden vi bruker knapper for å endre verdien
      />
      <Button
        className="mr-2"
        variant="outline-dark"
        onClick={() => handleEquipmentChange(key, "subtract")}
      >
        -
      </Button>
      <Button
        variant="outline-dark"
        onClick={() => handleEquipmentChange(key, "add")}
      >
        +
      </Button>
    </Form.Group>
  ))}
</Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => setShowEquipmentModal(false)}>
          Lukk
        </Button>
      </Modal.Footer>
    </Modal>
    <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigateToOtherSite(`/order/${orderIdLink}`)}
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {startDate} - {endDate}
              </div>
              <div>
                <strong className="font-medium">{packageType}</strong> -{" "}
                {orderId}
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phoneNUmber}
                    </strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div className="float-right">
                    <MdAttachMoney size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {totalPrice},-
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div>
                    <FaWarehouse size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">{storage}</strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  {status === "Ikke hentet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusUnknown
                          className="icon_ikkehentet"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Hentet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusDisabled className="icon_hentet" size={26} />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Levert" ? (
                    <>
                      <div className="float-right">
                        <GrStatusGood className="icon_levert" size={26} />
                      </div>
                      <div className="lg:ml-2">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <Container>
          <Row className="pt-3 lg:px-3" lg={1} xs={1}>
            <div className="float-right lg:pl-10">
              <Col xs={{span:12}}>
              <Button
                variant="secondary"
                className="text-white border-none bg-[#ff4122] text-left float-right inline ml-2"
                onClick={onClick}
              >
                X
              </Button>
              </Col>
              <Col  xs={{span:12}}>
              <Button
                variant="secondary"
                className={`text-white border-none bg-[#95bb72] text-left float-right  inline ${visibility}`}
                onClick={onClickRegnskap}
              >
                Regnskapsfør
              </Button>
              </Col >
              <Col  xs={{span:12}}>
              <Button
                variant="secondary"
                className={`text-[#94CD74] border-none text-left float-right inline mr-2 ${visibilityComment}`}
                onClick={onClickComment}
              >
                <FaRegCommentDots size={26} />
              </Button>
              </Col>
              <Col  xs={{span:12}}>
              <Button
                variant="secondary"
                className={`text-black border-none text-left float-right inline mr-2 ${visibilityNoComment}`}
                onClick={onClickComment}
              >
                <FaRegComment size={26} />
              </Button>
              <Button
              variant="secondary"
              className={`text-black border-none text-left float-right inline mr-2`}
  onClick={async () => {
    await fetchEquipmentData();
    setShowEquipmentModal(true);
  }}
>
<FaTools size={26} />
</Button>
              </Col>
            </div>
          </Row>
          </Container>
        </Card>
      </Col>
    </div>
  );
};

export default AdminCard;
