// @ts-nocheck
import { useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { doc, collection, query, getDocs, getDoc, updateDoc } from "firebase/firestore";
import AddTillegg from '../components/AddTillegg.tsx';
import AddPackage from '../components/AddPackage.tsx';
import EditSupplierPackage from '../components/EditSupplierPackage.tsx';
import {
  FaBeer,
  FaCoffee,
  FaMicrophone,
  FaMusic,
  FaLightbulb,
  FaVolumeUp,
  FaVolumeDown,
  FaHeadphones,
  FaPlug,
  FaBatteryFull,
  FaBolt,
  FaCameraRetro,
  FaCocktail,
  FaBroom
} from 'react-icons/fa';
import { db } from "../firebase1.ts";
import { arrayRemove } from 'firebase/firestore';

function getIconByName(iconName) {
  const icons = {
    FaBeer: <FaBeer size={48} />,
    FaCoffee: <FaCoffee size={48} />,
    FaMicrophone: <FaMicrophone size={48} />,
    FaMusic: <FaMusic size={48} />,
    FaLightbulb: <FaLightbulb size={48} />,
    FaVolumeUp: <FaVolumeUp size={48} />,
    FaVolumeDown: <FaVolumeDown size={48} />,
    FaHeadphones: <FaHeadphones size={48} />,
    FaPlug: <FaPlug size={48} />,
    FaBatteryFull: <FaBatteryFull size={48} />,
    FaBolt: <FaBolt size={48} />,
    FaCameraRetro: <FaCameraRetro size={48} />,
    FaCocktail: <FaCocktail size={48} />,
    FaBroom: <FaBroom size={48} />
  };

  return icons[iconName] || null;
}

function AdminSupplierCategories() {
  const [suppliers, setSuppliers] = useState([]);

  const supplierId = localStorage.getItem("supplierIdPL") || "";

  useEffect(() => {
    fetchSupplier();
}, []);

const fetchSupplier = async () => {
  try {
    const supplierRef = doc(db, "suppliers", supplierId);
    const supplierDoc = await getDoc(supplierRef);

    if(supplierDoc.exists()) {
      const data = supplierDoc.data();
      
      // Filtrerer kun aktive pakker
      const activePackages = data.packages.filter(pkg => pkg.status === "Active");

      // Filtrerer kun aktive tillegg
      const activeTillegg = data.tillegg ? data.tillegg.filter(tlg => tlg.status === "Active") : [];
      
      setSuppliers([{ ...data, packages: activePackages, tillegg: activeTillegg }]);
    } else {
      console.error("No supplier found with the given ID.");
    }
  } catch (err) {
    console.error(err);
  }
};

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  const handleDeactivatePackage = async (supplierId, packageId) => {
    const isConfirmed = window.confirm("Er du sikker på at du vil deaktivere pakken? Da vil den fjernes for videre utleie.");
    if (!isConfirmed) {
      return;
    }
    try {
        const supplierRef = doc(db, "suppliers", supplierId);
        const supplierDoc = await getDoc(supplierRef);

        if (!supplierDoc.exists()) {
            console.error("No supplier found with the given ID.");
            return;
        }

        const supplierData = supplierDoc.data();

        if (!supplierData || !supplierData.packages) {
            console.error("No packages found for the supplier.");
            return;
        }

        // Find the package to be deactivated
        const packageToUpdate = supplierData.packages.find(pkg => pkg.id === packageId);

        if (!packageToUpdate) {
            console.error("The package does not exist in the array.");
            return;
        }

        // Update package status to 'Inactive'
        packageToUpdate.status = "Inactive";

        // Update Firestore document with the updated array
        await updateDoc(supplierRef, { packages: supplierData.packages });

        console.log("The package has been deactivated.");
        fetchSupplier();
    } catch (error) {
        console.error("Error deactivating the package:", error);
    }
};

const handleDeactivateTillegg = async (supplierId, tilleggId) => {
  const isConfirmed = window.confirm("Er du sikker på at du vil deaktivere tillegget? Da vil det ikke lenger være tilgjenglig ved opprettelse av nye pakker.");
  if (!isConfirmed) {
    return;
  }
  try {
      const supplierRef = doc(db, "suppliers", supplierId);
      const supplierDoc = await getDoc(supplierRef);

      if (!supplierDoc.exists()) {
          console.error("No supplier found with the given ID.");
          return;
      }

      const supplierData = supplierDoc.data();

      if (!supplierData || !supplierData.tillegg) {
          console.error("No tillegg found for the supplier.");
          return;
      }

      // Find the tillegg to be deactivated
      const tilleggToUpdate = supplierData.tillegg.find(tilleggItem => tilleggItem.id === tilleggId);

      if (!tilleggToUpdate) {
          console.error("The tillegg does not exist in the array.");
          return;
      }

      // Update tillegg status to 'Inactive'
      tilleggToUpdate.status = "Inactive";

      // Update Firestore document with the updated array
      await updateDoc(supplierRef, { tillegg: supplierData.tillegg });

      console.log("The tillegg has been deactivated.");
      fetchSupplier();

  } catch (error) {
      console.error("Error deactivating the tillegg:", error);
  }
};

  return (
    <div className="mb-10">
      <Container>
        <div className="flex items-center pb-2 pt-10 lg:pl-28">
          <span className="font-josefin font-medium text-3xl tracking-wider">Mine pakker</span>
          <div className="font-josefin flex space-x-4 ml-4">
              <AddPackage supplierId={supplierId} />
          </div>
        </div>
        <div className="lg:pl-28 flex flex-wrap">
  {suppliers.length && suppliers[0].packages ? suppliers[0].packages.map((pkg: any) => (
    <div className="lg:w-1/3 p-4" key={pkg.id}>
      <div className="border rounded-lg overflow-hidden shadow-md bg-white">
        <div className="p-4">
          {getIconByName(pkg.bilder[0]) || 
            <img className="object-cover w-full h-48" src={pkg.bilder[0]} alt={pkg.navn} />
          }
        </div>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2">{pkg.navn}</h2>
          <p className="mb-4">{pkg.beskrivelse}</p>
          <p><strong className="font-medium">Startpris:</strong> {pkg.startPris}</p>
          <p className="my-2"><strong className="font-medium">Dagspris:</strong> {pkg.dagsPris}</p>
          <p className="mb-2"><strong className="font-medium">Tilleggsutstyr:</strong></p>
          <ul className="list-disc pl-5">
            {pkg.tillegg.map((tilleggItem, tilleggIdx) => (
              <li key={tilleggIdx}>{capitalize(tilleggItem)}</li>
            ))}
          </ul>
        </div>
        <div className="p-4 border-t">
        <EditSupplierPackage supplierId={supplierId} packageId={pkg.id} />
          <button
            className="text-white bg-red-400 hover:bg-red-600 shadow-md px-4 py-2 rounded transition"
            onClick={() => handleDeactivatePackage(supplierId, pkg.id)}
          >
            Deaktiver
          </button>
        </div>
      </div>
    </div>
  )) : null}
</div>

        <div className="flex items-center pb-2 pt-10 lg:pl-28">
        <span className="font-josefin font-medium text-3xl tracking-wider">Mitt tilleggsutstyr</span>
        <div className="font-josefin flex space-x-4 ml-4">
                <AddTillegg supplierId={supplierId} />
            </div>
        </div>
        <div className="lg:pl-28 flex flex-wrap">
  {suppliers.length && suppliers[0].tillegg ? suppliers[0].tillegg.map((tillegg) => (
    <div className="lg:w-1/4 p-4" key={tillegg.id}>
      <div className="border rounded-lg overflow-hidden shadow-md bg-white">
        <div className="flex items-center justify-center h-48 p-4">
          {getIconByName(tillegg.bilde) || 
            <img className="object-cover w-full h-full" src={tillegg.bilde} alt={tillegg.navn} />
          }
        </div>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2">{tillegg.navn}</h2>
          <p className="mb-4">{tillegg.beskrivelse}</p>
          <p><strong className="font-medium">Startpris:</strong> {tillegg.startPris}</p>
          <p className="my-2"><strong className="font-medium">Dagspris:</strong> {tillegg.dagsPris}</p>
        </div>
        <div className="p-4 border-t">
          <button
            className="text-white bg-red-400 hover:bg-red-600 shadow-md px-4 py-2 rounded transition"
            onClick={() => handleDeactivateTillegg(supplierId, tillegg.id)}
          >
            Deaktiver
          </button>
        </div>
      </div>
    </div>
  )) : null}
</div>
      </Container>
    </div>
  );
}

export default AdminSupplierCategories;