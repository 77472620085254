import { FC } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { BsPlus } from "react-icons/bs";
import {
  FaBeer,
  FaCoffee,
  FaMicrophone,
  FaMusic,
  FaLightbulb,
  FaVolumeUp,
  FaVolumeDown,
  FaHeadphones,
  FaPlug,
  FaBatteryFull,
  FaBolt,
  FaCameraRetro,
  FaCocktail,
  FaBroom,
} from "react-icons/fa";

interface AddOnsForPackageProps {
  name?: string;
  image?: string;
  desc?: string;
  num?: number;
  max?: number;
  priceFrom?: number;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddOnsForPackage: FC<AddOnsForPackageProps> = ({
  name = "",
  image = "",
  desc = "",
  max,
  priceFrom,
  onClick,
  onChange,
}) => {
  function getIconByName(iconName: any) {
    const icons = {
      FaBeer: <FaBeer size={48} />,
      FaCoffee: <FaCoffee size={48} />,
      FaMicrophone: <FaMicrophone size={48} />,
      FaMusic: <FaMusic size={48} />,
      FaLightbulb: <FaLightbulb size={48} />,
      FaVolumeUp: <FaVolumeUp size={48} />,
      FaVolumeDown: <FaVolumeDown size={48} />,
      FaHeadphones: <FaHeadphones size={48} />,
      FaPlug: <FaPlug size={48} />,
      FaBatteryFull: <FaBatteryFull size={48} />,
      FaBolt: <FaBolt size={48} />,
      FaCameraRetro: <FaCameraRetro size={48} />,
      FaCocktail: <FaCocktail size={48} />,
      FaBroom: <FaBroom size={48} />,
    };
    //@ts-ignore
    return icons[iconName] || null;
  }

  let objects = max ? Array.from({ length: max }, (_, i) => i + 1) : [];
  return (
    <div>
      <Container className="py-2 h-30">
        <Row>
          <Col
            xs={3}
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                width: "40vh",
                height: "10vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getIconByName(image) || (
                <img
                  src={image}
                  alt=""
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </Col>
          <Col xs={5} lg={7} md={8} sm={6} xl={8}>
            <div className="pt-2">
              <div>{name}</div>
              <div>
                + <strong>{priceFrom},-</strong>
              </div>
              <div>{desc}</div>
            </div>
          </Col>
          <Col xs={4} lg={3} md={2} sm={3} xl={2}>
            <Form.Control
              as="select"
              aria-label="Legg til"
              onClick={onClick}
              onChange={onChange}
              className="mt-2 px-2"
            >
              <option style={{ fontStyle: "bold" }}>Legg til</option>
              {objects.map((object) => (
                <option value={object}>{object}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <hr
          className="float-right object-right mt-3"
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "83%",
            float: "right",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default AddOnsForPackage;
