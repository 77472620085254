import React, { SetStateAction, useEffect, useState } from "react";
// @ts-ignore
import Collapsible from 'react-collapsible';
import {AiOutlineArrowUp} from 'react-icons/ai'
import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import {IoIosArrowDropdown} from 'react-icons/io'
// @ts-ignore
import { db } from "../firebase1.ts"
//@ts-ignore
import Card from "../components/Card.tsx";


export default function FaqPage () {

    const [faqs, setFaqs] = useState<DocumentData[]>([])

    const fetchData = async () => {
        const list: SetStateAction<DocumentData[]> = [];
        const q = query(collection(db, "dynamicPages"));
        try {
          const doc = await getDocs(q);
          doc.forEach((doc) => {
            if (doc.id === "FAQ") {
                var dat = doc.data()
              list.push(dat.questions);
            }
          });
          await setFaqs([...list]);
        } catch (e) {
          console.log(e);
        }
    };
    
    useEffect(() => {
    fetchData();

    }, []);

    var data = faqs[0]

    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
            <div className="w-full max-w-2xl p-4 bg-white shadow-md rounded-md divide-y divide-gray-200">
                <h1 className="text-2xl font-semibold mb-6 text-center">Ofte Stilte Spørsmål (FAQ)</h1>
                {data && data.map((faqItem:any, index:any) => (
                    <Card key={index} className="py-4">
                        <div>
                            <Collapsible 
                                trigger={faqItem.question} 
                                triggerClassName="text-lg font-medium px-4 py-2 cursor-pointer hover:bg-gray-200"
                                triggerOpenedClassName="text-lg font-medium px-4 py-2 cursor-pointer bg-gray-200"
                            >
                                <p className="text-gray-700 px-4 py-2">{faqItem.answer}</p>
                            </Collapsible>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}