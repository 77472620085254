/*eslint-disable*/
import React, { useEffect, useId, useState } from "react";

// @ts-ignore
import Invoice from "../components/Invoice.tsx";
import Cookies from "universal-cookie";
import { on } from "stream";
// @ts-ignore
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { ReactDOM } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { FC } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { getFunctions, httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router-dom";
import { DocumentData, doc, getDoc, addDoc, collection } from "firebase/firestore";
// @ts-ignore
import { app } from "../firebase1.ts";
// @ts-ignore
import { db } from "../firebase1.ts";
import { Console } from "console";
import { userInfo } from "os";

type OrderInfoType = DocumentData | null;

export default function InvoicePage () {

    const functions = getFunctions(app);
    const createInvoiceCheckout = httpsCallable(functions, "createInvoiceCheckout");

    const [orderComplete, setOrderComplete] = useState(false)

    const invoiceManager = httpsCallable(functions, "InvoiceManager")
    const autoAccounting = httpsCallable(functions, "autoAccounting")
    const [orderInfo, setOrderInfo] = useState<OrderInfoType>(null);
    const path = (new URL(window.location.toString())).pathname;
    const orderId = path.slice(9); // Extract orderId from URL

    if (!orderId) {
        // Handle the case where orderId is not available
        console.log("orderId is undefined");
        return;
    } 

    console.log(orderId)

    const cookie = new Cookies()

    const userinfo = cookie.get("userinfo")
    const fetchOrder = async () => {
        try {
            const orderRef = doc(db, "sales", orderId);
            const orderSnap = await getDoc(orderRef);

            if (orderSnap.exists()) {
                setOrderInfo(orderSnap.data()); // Denne linjen bør nå fungere uten feil.
            } else {
                console.log("Ingen slik ordre funnet");
            }
        } catch (error) {
            console.error("Feil ved henting av ordre: ", error);
        }
    };

    useEffect(() => {
        if (orderId) {
            fetchOrder();
        }
    }, [orderId]);


    console.log("orderInfo: "+orderInfo)
    const navigate = useNavigate()

    const [orgNumber, setOrgNumber] = useState(""); 
    
    const onSubmitInvoice = async (event: any) => {
        event.preventDefault();
    
        const data = {
            client_reference_id: orderId,
            userInfo: {
                email: userinfo.email,
                phonenumber: userinfo.phone_number,
                given_name: userinfo.name.split(" ")[0],
                family_name: userinfo.name.split(" ")[userinfo.name.split(" ").length - 1],
            },
            companyInfo: {
                orgNum: event.target.formOrgNummer.value,
                name: event.target.formNavnBedrift.value,
                phonenumber: userinfo.phone_number,
                email: userinfo.email, // Assuming the email is the same as in userInfo
                address: event.target.formAdresse.value,
                zip: event.target.formPostNummer.value
            }
        };
    
        // Send the data object inside another object with key 'data'
        createInvoiceCheckout(data).then((response) => {
            
                window.location.href = "/bestilt/10488b-47a2-a0dadb";
            
          });
    
        
    };

    async function getCookie(cookieID:string) {

        const c = cookie.get(cookieID)

        return c
        
    }

 
    
    

    

    return ( 
        <>
        {console.log("ORDER INFO: ", orderInfo)}
        {console.log("USERINFO: ", userinfo)}
        <>
      <div className="p-4 font-normal text-2xl tracking-wider whitespace-pre">
        Faktura for bedrift
      </div>
      {orderInfo ? (
                <div className="p-4">
                    Faktura sendes på e-post, og forfaller 10 dager etter første lånedato.
                    Et fakturagebyr på NOK 45,- kommer i tillegg. Totalbeløp blir{" "}
                    <strong>{Number(orderInfo.totalPris) + 45},-</strong>.
                </div>
            ) : (
                <div>Laster ordreinformasjon...</div> // Or some other placeholder
            )}
      
        <Form onSubmit={onSubmitInvoice}>
            <div className="text-xs p-4 text-[#24292e] tracking-widest">
            PERSONLIA
            </div>
            <Row md={2}>
            <Form.Group className="mb-3 p-4" controlId="formNavnMottaker">
                <Form.Label>Ansvarlig kontaktperson</Form.Label>
                <Form.Control
                placeholder={userinfo.name}
                disabled={true}
                
                />
            </Form.Group>

            <Form.Group className="p-4" controlId="formNavnBedrift">
                <Form.Label>Navn på bedrift</Form.Label>
                <Form.Control
                placeholder="Skriv inn navn på bedrift"
                required={true}

                
                />
            </Form.Group>
            <Form.Group className="p-4" controlId="formOrgNummer">
                <Form.Label>Org.nr. for bedrift</Form.Label>
                <Form.Control
                placeholder="eksempel 911 111 111"
                required={true}

                
                />
            </Form.Group>
            <Form.Group className="p-4" controlId="formAdresse">
                <Form.Label>Adresse</Form.Label>
                <Form.Control
                placeholder="Skriv inn adresse "
                required={true}

                />
            </Form.Group>
            <Form.Group className="p-4" controlId="formPostNummer">
                <Form.Label>Postnummer</Form.Label>
                <Form.Control
                placeholder="eksempel, 1111"
                required={true}

                />
            </Form.Group>
            <Form.Group className="p-4" controlId="formPostNummer">
                <Form.Label>E-post for faktura</Form.Label>
                <Form.Control
                placeholder="regnskap@dinbedrift.no"
                required={true}

                />
            </Form.Group>
            </Row>
            <div className="text-xs p-4 text-[#24292e] tracking-widest">
            KONTAKTINFORMASJON
            </div>
            <Row md={2}>
            <Form.Group className="p-4" controlId="formEmail">
                <Form.Label>E-post</Form.Label>
                <Form.Control
                placeholder={userinfo.email}
                disabled={true}
                
                />
            </Form.Group>
            <Form.Group className="p-4" controlId="formMobil">
                <Form.Label>Mobilnummer</Form.Label>
                <Form.Control
                placeholder={userinfo.phone_number}
                disabled={true}
                />
            </Form.Group>
            </Row>
            <Row>
            
            <Button
                type="submit"
                className="text-white bg-[#24292e] mt-3 m-4 ml-2 w-80"
                variant="secondary"
            >
                SEND FAKTURA OG BESTILL
            </Button>
            </Row>
        </Form>
    </>


        </>
    )

}