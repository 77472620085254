import { orderBy } from "firebase/firestore";
import { FC } from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

interface MyOrdersProps {
  startDate?: any;
  endDate?: any;
  packageType?: string;
  orderId?: any;
  phoneNumber?: number;
  totalPrice?: number;
  deliveryMethod?: string;
  storage?: string;
  supplierId?: string;
}

const MyOrders: FC<MyOrdersProps> = ({
  startDate,
  endDate,
  packageType = "",
  orderId,
  phoneNumber,
  totalPrice,
  storage = "",
  supplierId,
}) => {

  const orderLinkPath = supplierId ? `/order-leverandor/${orderId}` : `/order/${orderId}`;

  return (
    
    <div className="tracking-wide font-light font-josefin">
      <Row>
        <Col  xl={{ span: 5, offset: 3 }} className="g-4">
        <Card className="border rounded-md p-3 px-1">
        <Col className="py-6 px-3">
          <div className="font-medium text-xl tracking-wider pb-2">
            {startDate} - {endDate}
          </div>
          <div>
            <strong className="font-medium">{packageType}</strong>
          </div>
        </Col>
        <hr />
        <Col className="py-6 px-3">
          <div >
            <Link className="text-[#e57e22] underline tracking-wider font-medium" to={orderLinkPath}>
              Se bestilling 
            </Link>
          </div>
        </Col>
        <hr />
        <Col  className="py-6 px-3">
          <div>Mobilnummer: <strong className="font-medium">{phoneNumber}</strong></div>
        </Col>
        <hr />
        <Col  className="py-6 px-3">
          <div>Pris: <strong className="font-medium">{totalPrice}</strong>,-</div>
        </Col>
        <hr />
        <Col  className="py-6 px-3">
          <div>Lager:  <strong className="font-medium">{storage}</strong></div>
        </Col>
        </Card>
        </Col>
        
      </Row>
    </div>
  );
};

export default MyOrders;

