import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase1.ts"; // Pass på at denne importen fungerer med ditt byggverktøy

function generateRandomID() {
    return Math.floor(Math.random() * 10000001);  // Generate a random number between 0 and 10000000 inclusive
  }
  

function AddPackageModal({ isOpen, onClose, onSubmit, tilleggOptions, categoryOptions }) {

    const [packageData, setPackageData] = useState({
        beskrivelse: "",
        bilder: ["https://media.istockphoto.com/id/1354776457/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=w3OW0wX3LyiFRuDHo9A32Q0IUMtD4yjXEvQlqyYk9O4="],
        dagsPris: 100,
        kategori: "partytelt",
        navn: "",
        startPris: 299,
        tillegg: [],
        id: generateRandomID(),
        status: "Active"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        if (validateForm()) {
            onSubmit(packageData);
            
        }
    };

    const [validationErrors, setValidationErrors] = useState({});

    if (!isOpen) return null;

    const validateForm = () => {
        const errors = {};
    
        if (!packageData.navn.trim()) {
            errors.navn = "Navn er påkrevd.";
        }
    
        if (!packageData.beskrivelse.trim()) {
            errors.beskrivelse = "Beskrivelse er påkrevd.";
        }
    
        if (!packageData.startPris) {
            errors.startPris = "Oppstartspris er påkrevd.";
        }
    
        if (!packageData.dagsPris) {
            errors.dagsPris = "Pris per dag er påkrevd.";
        }

        if (packageData.bilder.length === 0) {
            errors.bilder = "Minst ett bilde er påkrevd.";
        }
    
        // ... kan utvide med flere valideringer ...
    
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;  // returnerer true hvis ingen feil
    };

    

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"> 
        <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-xl overflow-y-auto max-h-[90vh]">
            <h2 className="bg-white p-2" >Legg til pakke</h2>
                    <form onSubmit={handleFormSubmit}>
                    <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold">Navn:</label>
                            <input
                                type="text"
                                name="navn"
                                placeholder="Soundboks 3"
                                value={packageData.navn}
                                onChange={handleChange}
                                className="w-full p-2 border rounded"
                            />
                            {/* For navn */}
{validationErrors.navn && <p className="text-red-500 text-xs mt-1">{validationErrors.navn}</p>}

                        </div>
                        
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold">Beskrivelse:</label>
                            <textarea 
                                name="beskrivelse"
                                placeholder="Passer perfekt til ..."
                                value={packageData.beskrivelse}
                                onChange={handleChange}
                                className="w-full p-2 border rounded"
                            ></textarea>
                            {/* For beskrivelse */}
{validationErrors.beskrivelse && <p className="text-red-500 text-xs mt-1">{validationErrors.beskrivelse}</p>}


                        </div>

                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold">Oppstartspris:</label>
                            <input
    type="number"
    name="startPris" // Endre dette til riktig navn på tilstandsvariabelen
    placeholder="299"
    value={packageData.startPris}
    onChange={handleChange}
    className="w-full p-2 border rounded"
/>
{/* For startPris */}
{validationErrors.startPris && <p className="text-red-500 text-xs mt-1">{validationErrors.startPris}</p>}


        
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold">Pris per ekstra dag:</label>
                            <input
                                type="number"
                                name="dagsPris"
                                placeholder="100"
                                value={packageData.dagsPris}
                                onChange={handleChange}
                                className="w-full p-2 border rounded"
                            />
                            {/* For dagsPris */}
{validationErrors.dagsPris && <p className="text-red-500 text-xs mt-1">{validationErrors.dagsPris}</p>}
                        </div>

        
                        <div className="mb-4">
            <label className="block mb-2 text-sm font-bold">Kategori:</label>
            <select
                name="kategori"
                value={packageData.kategori}
                onChange={handleChange}
                className="w-full p-2 border rounded"
            >
                {categoryOptions.map(category => (
    <option key={category} value={category}>
        {category}
    </option>
))}
            </select>
        </div>
                        </div>
        
                        <div className="mb-4">
    <span className="block mb-2 text-sm font-bold">Hvilke tillegg skal være tilgjengelig? Tillegg må opprettes før pakken.</span>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {tilleggOptions.map(tillegg => (
            <label key={tillegg.navn} className="flex items-center space-x-2">
                <input 
                    type="checkbox" 
                    value={tillegg.navn} 
                    checked={packageData.tillegg.includes(tillegg.navn)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setPackageData(prev => ({
                                ...prev,
                                tillegg: [...prev.tillegg, e.target.value]
                            }));
                        } else {
                            setPackageData(prev => ({
                                ...prev,
                                tillegg: prev.tillegg.filter(item => item !== e.target.value)
                            }));
                        }
                    }}
                    className="form-checkbox text-blue-500 h-5 w-5"
                />
                <span>{tillegg.navn}</span>
            </label>
        ))}
    </div>
</div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold">Bilder (bildeadresse):</label>
                            <input
                                type="text"
                                name="bilder"
                                value={packageData.bilder.join(', ')} // Convert array to string for display
                                onChange={(e) => {
                                    const bilderArray = e.target.value.split(',').map(item => item.trim());
                                    setPackageData(prev => ({ ...prev, bilder: bilderArray }));
                                }}
                                placeholder="Lim inn bildeadresse"
                                className="w-full p-2 border rounded"
                            />
                            
{validationErrors.bilder && <p className="text-red-500 text-xs mt-1">{validationErrors.bilder}</p>}
                        </div>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2">Legg til Pakke</button>
                        <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Lukk</button>
                    </form>
                </div>
            </div>
        );
}

function AddPackage({ supplierId }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tilleggOptions, setTilleggOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    useEffect(() => {
        const fetchTilleggAndCategories = async () => {
            const supplierRef = doc(db, 'suppliers', supplierId);
            const supplierSnap = await getDoc(supplierRef);
    
            if (supplierSnap.exists()) {
                // Filtrer ut tillegg med status "Active"
                const activeTillegg = (supplierSnap.data().tillegg || []).filter(tillegg => tillegg.status === "Active");
                setTilleggOptions(activeTillegg);
    
                setCategoryOptions(supplierSnap.data().categories || []);
            }
        };
    
        fetchTilleggAndCategories();
    }, [supplierId]);

    const handleSubmit = async (packageData) => {
        const supplierRef = doc(db, 'suppliers', supplierId);
        const supplierSnap = await getDoc(supplierRef);

        if (!supplierSnap.exists()) {
            console.error("Finner ikke leverandørdokument med gitt ID.");
            return;
        }

        await updateDoc(supplierRef, {
            packages: arrayUnion(packageData)
        });

        console.log('Pakke lagt til!');
        setIsModalOpen(false);
        
    };

    return (
        <div className="flex justify-center py-10">
            <button className="bg-orange-500 hover:bg-orange-600 text-white w-8 h-8 text-2xl rounded transition duration-300" onClick={() => setIsModalOpen(true)}>+</button>
            <AddPackageModal 
    isOpen={isModalOpen} 
    onClose={() => setIsModalOpen(false)} 
    onSubmit={handleSubmit}
    tilleggOptions={tilleggOptions}
    categoryOptions={categoryOptions}
/>
        </div>
    );
}

export default AddPackage;