/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck

import {
  query,
  getDoc,
  getDocs,
  collection,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";


// @ts-ignore
import { db } from "../firebase1.ts";
import { getRandomValues } from "crypto";

/*
//Universell funksjon som henter data
async function getData(colId: string, docId: string) {
    const docSnap = await getDoc(doc(db, colId, docId));
    const data = docSnap.data();
    if (docSnap.exists()) {
      console.log("Document data retrieved:", data);
      return data;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async function gettingMore(){
    let packageType = "Soundboks 3";
    let packageData = await getData("packages", packageType);
    data.push(packageData);
    setStartPrice(data[0].startPris);
    setDescription(data[0].beskrivelse);
    setDayPrice(data[0].dagPris);r
    setContent(data[0].innhold);
  }

  gettingMore();
*/

/*  HENTE COLLECTION FRA DB 
const q = query(collection(db, "packages"));
const data = await getDocs(q)
    data.forEach((doc: { data: () => any; }) => {
        console.log(doc.data());
}) */

//Universell funksjon som henter data
/*
async function getData(colId, docId) {
    const docSnap = await getDoc(doc(db, colId, docId));
    const data = docSnap.data();
    if (docSnap.exists()) {
      console.log("Document data retrieved:", data.navn);
      return data;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }
  */

export async function getGearData(utstyr: string) {
  const docRef = doc(db, "gear", utstyr);
  try {
    const doc = await getDoc(docRef);
    //console.log("Data received about gear: ", doc.data())
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document: " + e);
  }
}

async function getData(colId: string, docId: string) {
  const docRef = doc(db, colId, docId);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getPackageDetails(packageType: any) {
  const docRef = doc(db, "packages", packageType);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getOrderDetails(orderId: any) {
  const docRef = doc(db, "sales", orderId);
  try {
    const doc = await getDoc(docRef);
    const data = doc.data();
    return data;
  } catch (e) {
    console.log("No such document! " + e);
  }
}

export async function getEquipment(packageType: string) {
  let packageData = await getData("packages", packageType);
  // @ts-ignore
  let equipBase = Object.keys(packageData.innhold);

  // Henter data for all utstyr samtidig ved bruk av Promise.all()
  let equipmentDataList = await Promise.all(equipBase.map(equip => getData("gear", equip)));

  let equipment = equipmentDataList.map((data, index) => {
      return {
          ...data,
          count: packageData.innhold[equipBase[index]],
          id: equipBase[index]
      };
  });

  return equipment;
}

export async function getMonteringsInfo(packageType: string) { 
  let packageData = await getData("packages", packageType);
  let monteringsInfo = packageData.montering

  /* console.log(monteringsInfo) */

  return monteringsInfo;
}

export async function getGearFromOrder(extraArray: Array) {
  let addons = [];
  if (extraArray != null) {
    for (let i in extraArray) {
      let data = await getData("gear", i);
      addons.push(data);
      addons[addons.length - 1].count = extraArray[i];
    }
  }
  return addons;
}

export async function getExtras(packageType: string) {
  let packageData = await getData("packages", packageType);
  // @ts-ignore
  let addonBase = packageData.tillegg;

  // Henter data for alle tillegg samtidig ved bruk av Promise.all()
  let addonDataList = await Promise.all(addonBase.map(addon => getData("gear", addon)));

  let addons = addonDataList.map((data, index) => {
      return {
          ...data,
          count: 0,
          id: addonBase[index]
      };
  });

  return addons;
}

let deliveryServiceBooked = {
  henting: false,
  levering: false,
  montering: false,
  adresse: "",
};

export async function checkOut(
  phonenumber: string,
  packageType: string,
  storage: any,
  insurance: any,
  startDate: number,
  endDate: number,
  rabattkode: string,
  deliveryServiceBooked: any[],
  chosenAddons: any[]
 ) {
  //Henter pakkedata
  let packageData = await getData("packages", packageType);
  // let packageData = await getEquipment(packageType);

  //Bestemmer addons i pakken
  let addonBase = packageData.tillegg;

  /*[
    "kabeltrommel",
    "mikrofonKablet",
    "mikrofonTrodlos",
    "stativ",
    "stativMikrofon",
    "soundboks3batteri",
    "roykmaskin",
    "strobelys",
    "miksebord",
    "stemningsbag",
    "stoler",
    "bord",
  ];


  let addons = new Array();

  //Henter addon data fra DB
  for (let i = 0; i < addonBase.length; i++) {
    let data = await getData("gear", addonBase[i]);
    addons.push(data);
    chosenAddons[addonBase[i]]
      ? (addons[i].count = chosenAddons[addonBase[i]])
      : (addons[i].count = 0); // Bruker
    addons[i].id = addonBase[i];
  }
  //Tidsopplysninger
  //@ts-ignore
  let totalDays = (endDate - startDate) / 86400000;
  let time = {
    startDate: startDate, // Bruker
    endDate: endDate, // Bruker
    totalDays: totalDays, // Må kalkuleres av range
    orderPlaced: new Date(), //Timestamp
  };

  console.log(deliveryServiceBooked);
  //Bestemmer leveringstjenester i pakken
  let deliveryService = ["levering", "henting", "montering", "adresse"];
  // Fetch delivery information from DB
  for (let i = 0; i < deliveryService.length; i++) {
    // @ts-ignore
    let serviceData = await getData("deliveryService", deliveryService[i]);
    // @ts-ignore
    let incomingServiceData = deliveryServiceBooked[deliveryService[i]];

    if (deliveryService[i] === "levering" || deliveryService[i] === "henting") {
      serviceData.order = incomingServiceData.order;
      serviceData.tidspunkt = incomingServiceData.tidspunkt;
    } else if (deliveryService[i] === "montering") {
      serviceData.order = incomingServiceData.order;
    } else {
      // for "adresse"
      serviceData.order = incomingServiceData;
    }

    console.log(serviceData);

    deliveryService[i] = serviceData;
  }

  console.log(deliveryService);

  //Til effektpakke?
  /* Venter med dette
let strobelys = false;  //Bruker
let roykmaskin = true;  //Bruker
*/

  /* Venter med dette
strobelys?addonPrice += strobelysStartPris + strobelysDagsPris * totalDays:"";
!roykmaskin?addonPrice -= roykmaskinStartPris + roykmaskinDagsPris * totalDays:"";
*/
  let totalDays = (endDate - startDate) / 86400000;
  let time = {
    startDate: startDate, // Bruker
    endDate: endDate, // Bruker
    totalDays: totalDays, // Må kalkuleres av range
    orderPlaced: new Date(), //Timestamp
  };

  //Bestemmer leveringstjenester i pakken
  let deliveryService = ["levering", "henting", "montering", "adresse"];
  // Fetch delivery information from DB
  for (let i = 0; i < deliveryService.length; i++) {
    // @ts-ignore
    let serviceData = await getData("deliveryService", deliveryService[i]);
    // @ts-ignore
    let incomingServiceData = deliveryServiceBooked[deliveryService[i]];

    if (deliveryService[i] === "levering" || deliveryService[i] === "henting") {
      serviceData.order = incomingServiceData.order;
      serviceData.tidspunkt = incomingServiceData.tidspunkt;
    } else if (deliveryService[i] === "montering") {
      serviceData.order = incomingServiceData.order;
    } else {
      // for "adresse"
      serviceData.order = incomingServiceData;
    }

    console.log(serviceData);

    deliveryService[i] = serviceData;
  }

  let allAddons = await getExtras(packageType);

  
  let rabattkodeValue;
  let rabattkodeCount;

  // Rabattkode informasjon
  if (rabattkode !== "") {
    let rabattkoden = await getData("discount", rabattkode);
    rabattkodeValue = rabattkoden.value;
    rabattkodeCount = rabattkoden.valid;
    if (rabattkodeCount === 0) {
      rabattkode === "";
    }
  }

  let payment = "Ikke betalt"; //Config this...

  //Kalkulerer pakkepris
  let packagePrice =
    packageData.startPris + packageData.dagsPris * time.totalDays;
  // @ts-ignore

  if (rabattkode !== "" && rabattkodeValue < 1) {
    let rabattertVerdiPackagePrice = Math.ceil(packagePrice * rabattkodeValue);
    packagePrice = packagePrice - rabattertVerdiPackagePrice;
  } else if (rabattkode !== "" && rabattkodeValue > 1) {
    packagePrice = packagePrice - rabattkodeValue;
  } else {
    packagePrice;
  }

  //Begynner kalkulasjon av totalpris
  let totalPrice = packagePrice;

  //Kalkulerer leveringspris
  let deliveryPrice = 0;
  if (totalPrice < 500) {
    // Setter leveringsprisen til henting-pris dersom totalpris er for lav -- OBS DETTE MÅ ENDRES HVIS VI FÅR NYE TRANSPORTØRER
    deliveryPrice = 399;
  }


  deliveryService.forEach((service) => {
    // @ts-ignore
    service.order ? (deliveryPrice += service.pris) : "";
  });
  totalPrice += deliveryPrice;

  //Kalkulerer total tilleggspris
  let totalAddonPrice = 0;
  allAddons.forEach((addon) => {

    if ( chosenAddons[addon.navn] )
    {
    
    totalAddonPrice +=
      addon.startPris * addon.count +
      addon.dagsPris * addon.count * time.totalDays;}
  });
  console.log("ADDON BACKEND" , chosenAddons)


  if (rabattkode !== "" && rabattkodeValue < 1) {
    let rabattertVerdiPackagePrice = Math.ceil(
      totalAddonPrice * rabattkodeValue
    );
    totalAddonPrice = totalAddonPrice - rabattertVerdiPackagePrice;
  } else if (rabattkode !== "" && rabattkodeValue > 1) {
    totalAddonPrice = totalAddonPrice - rabattkodeValue;
  } else {
    totalAddonPrice;
  }

  totalPrice += totalAddonPrice;

  //Legger til forsikring
  // @ts-ignore
  insurance ? (totalPrice += packageData.forsikring) : "";

  //Henter ut alle gear i ett object
  let gear = packageData.innhold;


  allAddons.forEach((addon) => {

    if (chosenAddons[addon.id] > 0) {
      if (!gear[addon.id]) {
        gear[addon.id] = 0
      }
      gear[addon.id] +=  chosenAddons[addon.id]
    }
    else {
      gear[addon.id] = addon.count;
    }
  });


  //Lagersstyringsfunksjonen
  function checkAvailability(
    gear: any,
    storage: any,
    startDate: any,
    endDate: any
  ) {}

  if(deliveryService[3].order === ""){
     deliveryService[0].order = false
  } else {
    deliveryService[0].order = true
  }

  console.log(deliveryService)
  console.log(deliveryService[3].order)

  // Legg til bestilling i databasen
  let orderId;

  let totalPriceDUMMY = await localStorage.getItem("totalprice")
  let totalAddonPriceDUMMY = await localStorage.getItem("totalAddonPrice")
   


  await addDoc(collection(db, "sales"), {
    tlf: "+47" + phonenumber,
    lager: storage,
    tid: time,
    deliveryService: deliveryService,
    utstyr: gear,
    pakkeType: packageType,
    pakkePris: packagePrice,
    tilleggsPris: parseInt(totalAddonPriceDUMMY,10),
    // @ts-ignore
    forsikring: { bestilt: insurance, pris: packageData.forsikring },
    totalPris: parseInt(totalPriceDUMMY, 10),
    betaling: payment,
    rabattkode: rabattkode,
    smsSent: false,
    status: "Ikke hentet",
    comment: "",
  })
    .then(function (docRef) {
      orderId = docRef.id;
    })
    .catch(function (error) {
      console.error("Error adding document: ", error);
    });

  /* SMS SERVICE
try {
    const docRef = await addDoc(collection(db, "messages"), {
      to: "+47"+phonenumber,
      from: "PartyLyd",
      body: "Takk for din bestilling av " + packageType
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
  */
  return orderId;
}
/*
Når man kaller checkout funksjon må objektet se slik ut
let deliverySeriviceBooked = {
  henting: false,
  levering: false,
  montering: false,
  adresse: {
    Gate:"",
    postnummer: "",
    poststed: ""
  }
}

async function checkout(phonenumber, packageType, storage, insurance, startDate, endDate, deliverySeriviceBooked) {
*/
/*
checkout(
  "41496901",
  "Kraftig lydpakke",
  "Lorenskog",
  true,
  new Date(2023, 1, 12),
  new Date(2023, 1, 14)
);
export checkout();

*/

//Sjekk tilgjengelige pakker gitt dato og sted - under konstruksjon
export async function checkAvaliablePackages(storage, startDate, endDate) {
  let gearUnavailable = new Array();

  let date = new Date(startDate);
  let date1 = new Date(endDate);
  let diffTime = date1.getTime() - date.getTime();
  let diffDays = diffTime / (1000 * 3600 * 24);

  //Hent ut alle rette datoer fra gearManagement til dateGov array
  let dateGov = new Array();
  let dateRefs = new Array();
  let currentDay = new Date(date);
  for (let i = 0; i < diffDays; i++) {
    let dbRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    let dateRef =
      "" +
      currentDay.getDate() +
      "." +
      (currentDay.getMonth() + 1) +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    dateGov.push(await getData("gearManagement", dbRef));
    dateRefs.push(dateRef);
  }

  //Henter ut alle pakker og lagrer i array
  const qPackages = query(collection(db, "packages"));
  const qPackagesSnapshot = await getDocs(qPackages);
  let packagesDB = new Object();
  qPackagesSnapshot.forEach((doc) => {
    packagesDB[doc.id] = doc.data().innhold;
  });

  //Henter all gear data og lagrer i gearDB array
  const q = query(collection(db, "gear"));
  const querySnapshot = await getDocs(q);
  //console.log(querySnapshot);
  let gearDB = new Object();
  querySnapshot.forEach((doc) => {
    gearDB[doc.id] = [doc.data()];
  });

  // eslint-disable-next-line array-callback-return
  Object.keys(packagesDB).map(function (k) {
    for (let i = 0; i < dateGov.length; i++) {
      let gear = packagesDB[k];
      let key = Object.keys(gear)[i];

      if (gearDB[key] !== undefined) {
        let capacity = gearDB[key][0].kapasitet[storage];
        let gearDBRef = dateGov[i];
        let equipment = gearDBRef[storage];

        for (let key in gear) {
          let gearCount = gear[key];
          let gearDBRefCount = equipment[key];

          //Denne skal være => ikke <
          if (gearDBRefCount + gearCount >= capacity) {
            if (!gearUnavailable.includes(Object.keys(packagesDB)[i])) {
              gearUnavailable.push(Object.keys(packagesDB)[i]);
            }
          }
        }
      } else {
        console.log("Key not found in gearDB:", key, gear);
      }
    }
  });
  let output = {
    gearUnavailable: gearUnavailable,
  };
  return output;
}

//Lagersstyring - SJEKK
export async function checkAvailability(gear, storage, start, endDate) {
  let totalAvailable = true;
  let gearAvailable = new Object();
  let gearUnavailable = new Array();

  //Hent ut alle rette datoer fra gearManagement til dateGov array
  let totDays = (endDate - start) / 86400000 + 1;
  let dateGov = new Array();
  let dateRefs = new Array();
  let currentDay = new Date(start);
  for (let i = 0; i < totDays; i++) {
    let dbRef =
      "" +
      currentDay.getDate() +
      "." +
      currentDay.getMonth() +
      "." +
      currentDay.getFullYear();
    let dateRef =
      "" +
      currentDay.getDate() +
      "." +
      (currentDay.getMonth() + 1) +
      "." +
      currentDay.getFullYear();
    currentDay.setDate(currentDay.getDate() + 1);

    dateGov.push(await getData("gearManagement", dbRef));
    dateRefs.push(dateRef);
  }

  //Henter all gear data og lagrer i gearDB array
  const q = query(collection(db, "gear"));
  const querySnapshot = await getDocs(q);
  let gearDB = new Object();
  querySnapshot.forEach((doc) => {
    gearDB[doc.id] = [doc.data()];
  });

  //Løkke som sjekker hvert gear bestilt
  for (let key in Object.keys(gear)) {
    //Nested løkke som sjekker hver dato
    for (let z = 0; z < dateGov.length; z++) {
      let booked = dateGov[z][storage][Object.keys(gear)[key]];
      let capacity = gearDB[Object.keys(gear)[key]][0].kapasitet[storage];
      let wanted = gear[Object.keys(gear)[key]];
      if (booked + wanted > capacity) {
        totalAvailable = false;
        gearUnavailable.push({
          gearName: gearDB[Object.keys(gear)[key]][0].navn,
          gearId: Object.keys(gear)[key],
          dateUnavailable: dateRefs[z],
        });
      }
      gearAvailable[Object.keys(gear)[key]] = capacity - booked;
    }
  }

  

  let output = {
    available: totalAvailable,
    allGear: gearAvailable,
    gearUnavailable: gearUnavailable,
  };
  return output;
}


export async function gearManagement(gear, storage, startDateSec, endDateSec, reverse) {

  //Hent ut alle rette datoer fra gearManagement til dateGov array
  let dateGov = new Array();
  let startDate = new Date(startDateSec*1000);
  let endDate = new Date(endDateSec*1000);
  let totalDays = (endDate-startDate)/86400000+1;

  startDate.setDate(startDate.getDate())

  for (let i = 0; i < totalDays; i++){
    let currentDay = startDate
    let dbRef = ""+currentDay.getDate() + "." + currentDay.getMonth() +"." +currentDay.getFullYear()
    currentDay.setDate(currentDay.getDate()+1)

    console.log(dbRef)
  
    let e = await getDoc(doc(db, "gearManagement", dbRef));

    dateGov.push(e.data())
}


  //Løkke som går gjennom hvert gear
  for (let key in Object.keys(gear)){
      //Nested løkke som oppdaterer hver dato
      for (let z=0; z<dateGov.length;z++){
          let booked = dateGov[z][storage][Object.keys(gear)[key]]
          let wanted = gear[Object.keys(gear)[key]]

          console.log("Loop: "+z)
          console.log(booked)
          console.log(wanted)

          let newBooked = reverse ? booked-wanted : booked+wanted
          dateGov[z][storage][Object.keys(gear)[key]] = newBooked;

          console.log(newBooked)
  }
  }
  console.log(dateGov)

  startDate = new Date(startDateSec*1000);
  startDate.setDate(startDate.getDate())

  for (let i = 0; i < totalDays; i++){
    let currentDay = startDate
    let docRef = ""+currentDay.getDate() + "." + currentDay.getMonth() +"." +currentDay.getFullYear()
    currentDay.setDate(currentDay.getDate()+1)
    
    await setDoc(doc(db, "gearManagement", docRef), dateGov[i]);
  }

  return true;
}





