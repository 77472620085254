import GoogleMapReact from "google-map-react";
import { Card, Col, Row, Container } from "react-bootstrap";
import { FC } from "react";
import logo from '../img/logoTransparent.png';
import { FaMapPin } from 'react-icons/fa';

const Marker = () => (
  <div>
    <img src={logo} alt="Location Marker" style={{ width: '60px', height: '60px', transform: 'translate(-50%, -50%)' }} />
  </div>
);

const MarkerSupplier = ({ onClick }) => (
    <div
  onClick={onClick}
  style={{
    fontSize: "1rem",
    transform: "translate(-50%, -50%)",
  }}
>
  <FaMapPin style={{ color: "rgba(244, 155, 16, 0.5)" }} />
</div>
  );

// @ts-ignore
const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "white",
      background: "#f7b710",
      fontSize: "0.8rem",
      fontStyle: "bold",
      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

interface LocationProps {
  style: any;
  color: string;
}

const Location: FC<LocationProps> = ({ color }) => {
  const defaultProps = {
    center: {
      lat: 61.23832,
      lng: 9.39314,
    },
    zoom: 6,
  };
  return (

<div className="mb-10">
    <Container>
    <Row>
            <Col md={{ span: 6 }} xs={{ span: 12 }}>
                <div className="pb-1 px-2">
                    <div
                        className={`md:pl-20 lg:pt-10 pt-6 font-bold text-3xl tracking-wider text-${color} font-josefin`}
                    >
                        Du finner oss og leverandører her
                    </div>
                    <Row>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Lørenskog</Card.Title>
            <Card.Subtitle className="pb-2">Oskar Braatens vei 6</Card.Subtitle>
            <Card.Subtitle className="pb-2">1472 Fjellhamar</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Asker</Card.Title>
            <Card.Subtitle className="pb-2">Bleikerveien 15</Card.Subtitle>
            <Card.Subtitle className="pb-2">1387 Asker</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
    </Col>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Vinterbro</Card.Title>
            <Card.Subtitle className="pb-2">Sundbyveien 90</Card.Subtitle>
            <Card.Subtitle className="pb-2">1407 Vinterbro</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Trondheim</Card.Title>
            <Card.Subtitle className="pb-2">Sørenga 9</Card.Subtitle>
            <Card.Subtitle className="pb-2">7032 Trondheim</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
    </Col>
    
</Row>
<Row>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Stavanger</Card.Title>
            <Card.Subtitle className="pb-2">Haugåsstubben 14</Card.Subtitle>
            <Card.Subtitle className="pb-2">4016 Stavanger</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
    </Col>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Kristiansand</Card.Title>
            <Card.Subtitle className="pb-2">Rigedalen 49</Card.Subtitle>
            <Card.Subtitle className="pb-2">4626 Kristiansand</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
    </Col>
</Row>
<Row>
<Col md={{ span: 6 }} xs={{ span: 12 }}>
        <Card className="p-4 mt-3 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Bergen</Card.Title>
            <Card.Subtitle className="pb-2">Spelhaugen 18</Card.Subtitle>
            <Card.Subtitle className="pb-2">5147 Fyllingsdalen</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Lageret er alltid åpent, og du kan hente og levere når som helst på døgnet.
            </Card.Text>
        </Card>
        
        
    </Col>
    <Col md={{ span: 6 }} xs={{ span: 12 }}>
    <Card className="p-6 mt-3 pb-8 font-josefin">
            <Card.Title className="font-bold pb-2 tracking-wider">Leverandørnettverk</Card.Title>
            <Card.Subtitle className="pb-2">Du kan bestille utstyr fra vårt nettverk av leverandører over hele Norge.</Card.Subtitle>
            <Card.Text className="font-normal tracking-wide">
                Henting og levering avtales direkte med leverandøren.
            </Card.Text>
        </Card>
        </Col>
    </Row>
                </div>
            </Col>
            <Col md={{ span: 5, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                <div className="pt-16 md:pt-20 md:pb-10 px-2 pb-1">
                <div style={{ height: "85vh", width: "100%", borderRadius: "10px", overflow: "hidden" }}>
                    <GoogleMapReact
    defaultCenter={defaultProps.center}
    defaultZoom={defaultProps.zoom}
>

{[
        { lat: 59.871582, lng: 10.4887606},
        { lat: 59.8609898, lng: 10.4724009},
        { lat: 59.8325935, lng: 10.4395419, text: "Asker" },
        { lat: 59.9125346, lng: 10.5989175},
        { lat: 59.1312, lng: 10.0352},
        { lat: 58.2528814, lng: 7.9877018},
        { lat: 58.4093001, lng: 8.7064578},
        { lat: 63.4440182, lng: 10.4303902},
        { lat: 59.888588, lng: 10.790954},
        { lat: 59.7957742, lng: 10.4262772},
        { lat: 59.1405, lng: 9.6564},
        { lat: 63.3311195, lng: 10.3107013},
        { lat: 63.4430778, lng: 10.4468695},
        { lat: 63.4341071, lng: 10.4170621},
        { lat: 59.0533, lng: 10.0352},
        { lat: 63.4258928, lng: 10.3895141},
        { lat: 59.6617418, lng: 9.6032072},
        { lat: 59.6617418, lng: 9.6032072},
        { lat: 63.442358, lng: 10.4397971},
        { lat: 59.1312, lng: 10.2166},
        { lat: 59.2184, lng: 10.9381},
        { lat: 59.8824016, lng: 10.4922674},
        { lat: 59.871582, lng: 10.4887606},
        { lat: 59.8609898, lng: 10.4724009},
        { lat: 59.9125346, lng: 10.5989175},
        { lat: 59.1312, lng: 10.0352},
        { lat: 58.2528814, lng: 7.9877018},
        { lat: 58.4093001, lng: 8.7064578},
        { lat: 63.4440182, lng: 10.4303902},
        { lat: 59.888588, lng: 10.790954},
        { lat: 59.7957742, lng: 10.4262772},
        { lat: 59.1405, lng: 9.6564},
        { lat: 63.3311195, lng: 10.3107013},
        { lat: 63.4430778, lng: 10.4468695},
        { lat: 63.4341071, lng: 10.4170621},
        { lat: 59.0533, lng: 10.0352},
        { lat: 63.4258928, lng: 10.3895141},
        { lat: 59.6617418, lng: 9.6032072},
        { lat: 63.442358, lng: 10.4397971},
        { lat: 59.1312, lng: 10.2166},
        { lat: 59.2184, lng: 10.9381},
        { lat: 59.8824016, lng: 10.4922674},
        { lat: 63.1095904, lng: 7.7751043},
        { lat: 59.7595436, lng: 9.881413},
        { lat: 59.3936617, lng: 5.2965331},
        { lat: 58.8497671, lng: 5.7408337},
        { lat: 58.8497671, lng: 5.7408337},
        { lat: 59.3626995, lng: 10.3645399},
        { lat: 60.1002033, lng: 11.1393321},
        { lat: 60.3840314, lng: 5.2485344},
        { lat: 59.3936617, lng: 5.2965331},
        { lat: 58.8497671, lng: 5.7408337},
        { lat: 63.4215512, lng: 10.4941908},
        { lat: 63.3295811, lng: 10.2674472},
        { lat: 58.8081066, lng: 5.8242666},
        { lat: 59.9198804, lng: 9.9626529},
        { lat: 58.8269167, lng: 5.7480096},
        { lat: 59.3626995, lng: 10.3645399},
        { lat: 59.4177644, lng: 5.2816442},
        { lat: 59.8466939, lng: 10.8187247},
        { lat: 59.794998, lng: 10.4736152},
        { lat: 63.2642113, lng: 10.289607},
        { lat: 59.90359, lng: 10.4863393},
        { lat: 58.8497671, lng: 5.7408337},
        { lat: 59.7716606, lng: 10.8431283},
        { lat: 62.5128394, lng: 6.0699618},
        { lat: 60.3950982, lng: 5.3165051},
        { lat: 59.9173525, lng: 10.787792},
        { lat: 59.9501608, lng: 10.9987438},
        { lat: 60.344593, lng: 5.3809293},
        { lat: 59.9565201, lng: 10.4774839},
        { lat: 60.82514, lng: 11.3240836},
        { lat: 58.9140405, lng: 5.7360123},
        { lat: 59.9447041, lng: 11.013427},
        { lat: 59.701787, lng: 10.758232},
        { lat: 60.3840314, lng: 5.2485344},
        { lat: 63.4312114, lng: 10.4553396},
        { lat: 59.4299496, lng: 10.6736053},
        { lat: 63.3295811, lng: 10.2674472},
        { lat: 58.8081066, lng: 5.8242666},
        { lat: 58.8081066, lng: 5.8242666},
        { lat: 59.9198804, lng: 9.9626529},
        { lat: 58.8269167, lng: 5.7480096},
        { lat: 59.3626995, lng: 10.3645399},
        { lat: 59.4177644, lng: 5.2816442},
        { lat: 59.8466939, lng: 10.8187247},
        { lat: 59.794998, lng: 10.4736152},
        { lat: 63.2642113, lng: 10.289607},
        { lat: 59.90359, lng: 10.4863393},
        { lat: 58.8497671, lng: 5.7408337},
        { lat: 59.7716606, lng: 10.8431283},
        { lat: 59.7716606, lng: 10.8431283},
        { lat: 59.7716606, lng: 10.8431283},
        { lat: 62.5128394, lng: 6.0699618},
        { lat: 60.3950982, lng: 5.3165051},
        { lat: 59.9173525, lng: 10.787792},
        { lat: 59.9501608, lng: 10.9987438},
        { lat: 60.344593, lng: 5.3809293},
        { lat: 59.9565201, lng: 10.4774839},
        { lat: 60.82514, lng: 11.3240836},
        { lat: 58.9140405, lng: 5.7360123},
        { lat: 59.9447041, lng: 11.013427},
        { lat: 59.701787, lng: 10.758232},
        { lat: 60.3840314, lng: 5.2485344},
        { lat: 63.4312114, lng: 10.4553396},
        { lat: 59.4299496, lng: 10.6736053}
    ].map(location => (
        <MarkerSupplier
            key={location.text}
            lat={location.lat}
            lng={location.lng}
        />
    ))}

{[
        { lat: 63.40649400343276, lng: 10.418490147036561, text: "Trondheim" },
        { lat: 59.937339897357525, lng: 10.974654223440327, text: "Lørenskog" },
        { lat: 59.8325935, lng: 10.4395419, text: "Asker" },
        { lat: 59.70188425922689, lng: 10.758360444768606, text: "Vinterbro" },
        { lat: 58.94556836897725, lng: 5.725300015090888, text: "Stavanger" },
        { lat: 58.1389865, lng: 7.9263283, text: "Kristiansand" },
        { lat: 60.3545537, lng: 5.2511893, text: "Bergen" }
    ].map(location => (
        <Marker
            key={location.text}
            lat={location.lat}
            lng={location.lng}
        />
    ))}
</GoogleMapReact>

                    </div>
                    
                </div>
            </Col>
        </Row>
    </Container>
</div>

  );
};

export default Location;
