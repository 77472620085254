// @ts-nocheck

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home.tsx";
import OurPackages from "./pages/OurPackages.tsx";
import RentalConditions from "./pages/RentalConditions.tsx";
import Location from "./pages/Location.tsx";
import Contact from "./pages/Contact.tsx";
import Packages from "./pages/Packages.tsx";
import OrderDetail from "./pages/OrderDetail.tsx";
import Admin from "./pages/Admin.tsx";
import NavBar from "./components/NavBar.tsx";
import FindPackage from "./pages/FindPackage.tsx";
import DeliveryOrders from "./pages/DeliveryOrders.tsx";
import SearchPackages from "./pages/SearchPackages.tsx";
import NavBarAdmin from "./components/NavBarAdmin.tsx";
import InventoryControl from "./pages/InventoryControl.tsx";
import Besokslogg from "./pages/Besokslogg.tsx";
import AdminRabattkoder from "./pages/AdminRabattkoder.tsx";
import FindMyOrder from "./pages/FindMyOrder.tsx";
import OrderComplete from "./pages/OrderComplete.tsx";
import NotFound from "./pages/NotFound.tsx";
import Footer from "./components/Footer.tsx";
import AdminDamage from "./pages/AdminDamage.tsx";
import AdminDemand from "./pages/AdminDemand.tsx";
import Newsfeed from "./pages/Newsfeed.tsx";
import AdminActiveOrders from "./pages/AdminActiveOrders.tsx";
import AdminOrdersMadeToday from "./pages/AdminOrdersMadeToday.tsx";
import LogIn from "./pages/LogIn.tsx";
import Maintance from "./pages/Maintance.tsx";
import MaintanceChecklist from "./pages/MaintanceChecklist.tsx";
import NavBarMaintance from "../src/components/NavBarMaintance.tsx";
import { RoleProvider } from "./components/RoleProvider.tsx";
import AdminSupplier from "./pages/AdminSupplier.tsx";
import Supplier from "./pages/Supplier.tsx";
import SupplierCategory from "./pages/SupplierCategory.tsx";
import SupplierSale from "./pages/SupplierSale.tsx";
import SupplierOrderDetail from "./pages/SupplierOrderDetail.tsx";
import AdminSupplierMessageService from "./pages/AdminSupplierMessageService.tsx";
import AdminSupplierTasks from "./pages/AdminSupplierTasks.tsx";
import AdminSupplierCategories from "./pages/AdminSupplierCategories.tsx";
import SuperAdmin from "./pages/SuperAdminSupplier.tsx";
import AdminChecklist from "./pages/AdminChecklist.tsx";
// @ts-ignore
import NavBarSupplier from "../src/components/NavBarSupplier.tsx";
import FaqPage from "./pages/FaqPage.tsx";
import VippsCallbackPage from "./pages/VippsCallback.tsx";
import InvoicePage from "./pages/InvoicePage.tsx";

function App() {
  let pathArray = window.location.pathname;

  const adminArray: Array<String> = [
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631",
    "/admin-levering",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/lagerstyring",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/besokslogg",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/rabattkoder",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/skader",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/foresporsler",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/vedlikehold",
    "/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/leverandor",
  ];

  const maintenanceArray: Array<String> = [
    "/maintance/26f3eb3a-6f6c-48fe-bba8-31e9ab581631",
    "/maintance/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/vedlikehold",
  ];

  const supplierArray: Array<String> = [
    "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631",
    "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/sms-service",
    "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/tasks",
    "/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/categories",
  ];

  return (
    <div className="page-container">
      <div className="content-wrap">
        <RoleProvider>
          {adminArray.includes(pathArray) ? (
            <NavBarAdmin />
          ) : maintenanceArray.includes(pathArray) ? (
            <NavBarMaintance />
          ) : supplierArray.includes(pathArray) ? (
            <NavBarSupplier />
          ) : (
            <NavBar />
          )}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/pakker" element={<OurPackages />} />
              <Route path="/logg-inn" element={<LogIn />} />
              <Route path="/hvor-finner-du-oss" element={<Location />} />
              <Route path="/leiebetingelser" element={<RentalConditions />} />
              <Route path="/finn-min-bestilling" element={<FindMyOrder />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/leverandor" element={<Supplier />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route
                path="/leverandor/:categoryName"
                element={<SupplierCategory />}
              />
              <Route
                path="/leverandor/:supplierId/:categoryName/:idPackage"
                element={<SupplierSale />}
              />
              <Route
                path="/order-leverandor/:orderId"
                element={<SupplierOrderDetail />}
              />
              <Route path="/pakke/:packageNameId" element={<Packages />} />
              <Route
                path="/pakke/:packageNameId/:sDate/:eDate"
                element={<Packages />}
              />
              <Route path="/vippsCallbackPage" element={<VippsCallbackPage></VippsCallbackPage>}></Route>
            <Route path="/invoice/:orderId" element={<InvoicePage></InvoicePage>}></Route>
            <Route path="/order/:orderId" element={<OrderDetail />}></Route>
              <Route path="/finn-riktig-pakke" element={<FindPackage />} />
              <Route
                path="/pakker/:typePackage/:location/:startDate/:endDate"
                element={<SearchPackages />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631"
                element={<Admin />}
              />
              <Route path="/admin-levering" element={<DeliveryOrders />} />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/lagerstyring"
                element={<InventoryControl />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/aktive-bestillinger"
                element={<AdminActiveOrders />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/dagens-bestillinger"
                element={<AdminOrdersMadeToday />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/leverandor"
                element={<SuperAdmin />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/besokslogg"
                element={<Besokslogg />}
              />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/rabattkoder"
                element={<AdminRabattkoder />}
              />
              <Route
                path="/bestilt/10488b-47a2-a0dadb"
                element={<OrderComplete />}
              ></Route>
              <Route path="*" element={<NotFound />} />
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/skader"
                element={<AdminDamage />}
              ></Route>
              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/foresporsler"
                element={<AdminDemand />}
              ></Route>

              <Route
                path="/admin/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/vedlikehold"
                element={<AdminChecklist></AdminChecklist>}
              ></Route>

              <Route path="/aktuelt" element={<Newsfeed />}></Route>
              <Route
                path="/maintance/26f3eb3a-6f6c-48fe-bba8-31e9ab581631"
                element={<Maintance />}
              />
              <Route
                path="/maintance/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/vedlikehold"
                element={<MaintanceChecklist />}
              />
              <Route
                path="/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631"
                element={<AdminSupplier />}
              />
              <Route
                path="/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/sms-service"
                element={<AdminSupplierMessageService />}
              />
              <Route
                path="/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/tasks"
                element={<AdminSupplierTasks />}
              />
              <Route
                path="/supplier/26f3eb3a-6f6c-48fe-bba8-31e9ab581631/categories"
                element={<AdminSupplierCategories />}
              />
            </Routes>
          </BrowserRouter>
        </RoleProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
